import { Button, ButtonProps } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface ExpandButtonProps extends ButtonProps {
  expanded: boolean;
}

export default function ExpandButton({
  expanded,
  onClick,
  sx,
  ...other
}: ExpandButtonProps) {
  return (
    <Button
      variant="text"
      color="primary"
      sx={{ width: "fit-content", marginTop: expanded ? "16px" : "0px", ...sx }}
      onClick={onClick}
      endIcon={!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    >
      {expanded ? "Show less" : "Show more"}
    </Button>
  );
}
