import { createSlice, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit";
import { ReportTemplateItem } from "../../models/index";
import { api } from "../../../../api/twelve";

export interface ReportTemplatesState {
  items: Array<ReportTemplateItem>;
  loading: boolean;
  error: string | null;
}

const initialState: ReportTemplatesState = {
  items: [],
  loading: false,
  error: null,
};

const reportTemplatesSlice = createSlice({
  name: "reportTemplates",
  initialState,
  reducers: {
    fetchItemsStart: (state) => {
      state.loading = true;
    },
    fetchItemsSuccess: (
      state,
      action: PayloadAction<{ items: Array<ReportTemplateItem> }>,
    ) => {
      state.items = action.payload.items;
      state.loading = false;
    },
    addItem: (state, action: PayloadAction<ReportTemplateItem>) => {
      state.items.unshift(action.payload);
    },
    updateItem: (state, action: PayloadAction<ReportTemplateItem>) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.items = [
          ...state.items.slice(0, index),
          action.payload,
          ...state.items.slice(index + 1),
        ];
      }
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    fetchItemsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchItemsStart,
  fetchItemsSuccess,
  fetchItemsFailure,
  addItem,
  updateItem,
  deleteItem,
} = reportTemplatesSlice.actions;
export default reportTemplatesSlice.reducer;

// Thunk actions for API calls
export const fetch: any =
  (token: string) =>
  async (
    dispatch: ThunkDispatch<ReportTemplatesState, void, PayloadAction<any>>,
  ) => {
    try {
      dispatch(reportTemplatesSlice.actions.fetchItemsStart());
      const created_before = new Date();
      created_before.setDate(created_before.getDate() + 1);
      const response = await api.getReportTemplates(token, created_before);
      dispatch(
        reportTemplatesSlice.actions.fetchItemsSuccess({ items: response }),
      );
    } catch (error: any) {
      console.error("Failed to fetch templates:", error);
      dispatch(reportTemplatesSlice.actions.failure(error.message));
    }
  };
