import {
  Button,
  Divider,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useEffect, useState } from "react";
import { api } from "../../../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ChatFunctionModel,
  PlayerComparisonContext,
  PlayerEvaluationContext,
  PlayerTransferContext,
} from "../../../../models";
import { AnalystChatFunctionSelectProps } from "../common-components/BaseAnalystChatFunctionSelect";
import SelectPlayer, {
  SelectedPlayer,
} from "../common-components/SelectPlayer";
import { useChatContext } from "../../ChatContext";
import StringHelper from "../../../../shared/helpers/string.helper";
import ExpandButton from "../common-components/ExpandButton";
import { GenderType } from "../../../../models/types/GenderType";

const ScoutPlayerAnalystChatFunctionSelect: React.FC<
  AnalystChatFunctionSelectProps
> = ({ onChatFunctionSelected }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { chatContext } = useChatContext();

  const [tasks, setTasks] = useState<Array<ChatFunctionModel>>([]);
  const [selectedTask, setSelectedTask] = useState<
    ChatFunctionModel | undefined
  >(undefined);
  const [selectedContext, setSelectedContext] = useState<
    PlayerEvaluationContext | PlayerComparisonContext | PlayerTransferContext
  >();
  const [preSelectedPlayer, setPreSelectedPlayer] = useState<SelectedPlayer>();
  const [expanded, setExpanded] = useState([false, false, false]);
  const [gender, setGender] = useState<GenderType | undefined>(undefined);
  const [other_gender, setOtherGender] = useState<GenderType | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!chatContext?.analyst) {
      setPreSelectedPlayer(undefined);
      setGender(undefined);
      setOtherGender(undefined);
      return;
    }

    const getAnalystChatFunctions = async () => {
      const token = await getAccessTokenSilently();
      const result = (
        await api.getAnalystChatFunctions(token, chatContext.analyst)
      ).sort((a, b) => {
        if (a.name === "redirect_to_scout") return -1;
        return 1;
      });
      setTasks(result);
    };

    getAnalystChatFunctions();

    const getPlayerInfo = async () => {
      const playerContext = chatContext.context as PlayerEvaluationContext;
      if (!playerContext || !playerContext.player) return;
      const token = await getAccessTokenSilently();
      const player = await api.getPlayerSeasonInfo(
        token,
        playerContext.player.id,
        playerContext.competition.id,
        playerContext.competition.year,
        playerContext.player.position,
      );

      if (!player) return;

      setPreSelectedPlayer({
        info: {
          id: player.id,
          position: player.position,
          name: player.synthesized_name,
          relevant_qualities: player.relevant_qualities?.map((q: any) => ({
            quality: q,
            display_name: StringHelper.normalizeKey(q),
          })),
          gender: player?.competition?.gender
            ? player?.competition?.gender?.nouns === "men"
              ? "male"
              : "female"
            : undefined,
        },
        competition: {
          id: player.competition.id,
          year: player.competition.year,
        },
        quality: {
          quality: player.quality?.quality,
        },
      } as SelectedPlayer);
    };

    getPlayerInfo();
  }, [chatContext, getAccessTokenSilently]);

  useEffect(() => {
    setGender(preSelectedPlayer?.info.gender);
  }, [preSelectedPlayer]);

  useEffect(() => {
    setExpanded([false, false, false]);
    if (!selectedTask && tasks.length > 0) {
      setSelectedTask(tasks[0]);
    }
  }, [tasks, selectedTask]);

  useEffect(() => {
    setSelectedContext(undefined);
  }, [selectedTask]);

  const handleTaskChange = (event: SelectChangeEvent) => {
    setSelectedTask(tasks.find((t) => t.name === event.target.value));
  };

  const isValidSelectedContext = (): boolean => {
    if (
      !(
        selectedTask &&
        selectedContext?.player?.id &&
        selectedContext?.competition?.id &&
        selectedContext?.competition?.year
      )
    )
      return false;
    if (
      selectedTask.name === "redirect_to_comparison" &&
      !(
        (selectedContext as PlayerComparisonContext).other_player?.id &&
        (selectedContext as PlayerComparisonContext).other_competition?.id &&
        (selectedContext as PlayerComparisonContext).other_competition?.year
      )
    )
      return false;
    if (
      selectedTask.name === "redirect_to_transfer" &&
      !(
        (selectedContext as PlayerTransferContext).other_competition?.id &&
        (selectedContext as PlayerTransferContext).other_competition?.year
      )
    )
      return false;

    return true;
  };

  return (
    <Stack spacing={4} p={"24px 0"}>
      <Stack
        direction="row"
        spacing={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="task-label">Task</InputLabel>
          <Select
            labelId="task-label"
            id="task-select"
            value={selectedTask?.name || ""}
            label="Task"
            onChange={handleTaskChange}
          >
            {tasks.map((item: any) => (
              <MenuItem key={item.name} value={item.name}>
                {item.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip title={selectedTask?.description}>
          <Icon sx={{ color: "text.primary" }}>
            <InfoOutlinedIcon />
          </Icon>
        </Tooltip>
      </Stack>
      <Divider />
      <Stack gap={"32px"}>
        {selectedTask?.name === "redirect_to_scout" && (
          <Stack gap={"9px"}>
            <SelectPlayer
              variant="evaluate"
              defaultPlayer={preSelectedPlayer}
              expanded={expanded[0]}
              onSelect={(player) => {
                setSelectedContext({
                  version: "1",
                  competition: {
                    id: player?.competition?.id,
                    year: player?.competition?.year,
                  },
                  player: {
                    id: player?.info?.id,
                    position: player?.info?.position,
                  },
                  quality: player?.quality?.quality,
                } as PlayerEvaluationContext);
              }}
            />
            <ExpandButton
              expanded={expanded[0]}
              onClick={() =>
                setExpanded(() => {
                  const newExpanded = [...expanded];
                  newExpanded[0] = !newExpanded[0];
                  return newExpanded;
                })
              }
            />
          </Stack>
        )}
        {selectedTask?.name === "redirect_to_comparison" && (
          <Stack gap={"9px"}>
            <SelectPlayer
              variant="comparison"
              expanded={expanded[1]}
              label="Player 1"
              defaultPlayer={preSelectedPlayer}
              position={
                (selectedContext as PlayerComparisonContext)?.other_player
                  ?.position
              }
              gender={gender || other_gender}
              onSelect={(player) => {
                setSelectedContext((prev) => {
                  return {
                    ...(prev || { version: "1" }),
                    competition: {
                      id: player?.competition?.id,
                      year: player?.competition?.year,
                    },
                    player: {
                      id: player?.info?.id,
                      position: player?.info?.position,
                    },
                  } as PlayerComparisonContext;
                });
                setGender(player?.info?.gender);
              }}
            />
            {expanded[1] && <Divider sx={{ marginTop: "32px" }} />}
            <SelectPlayer
              variant="comparison"
              expanded={expanded[1]}
              gender={gender || other_gender}
              label="Player 2"
              noOptionsText="Comparisons only possible within the same position."
              position={
                (selectedContext as PlayerComparisonContext)?.player?.position
              }
              onSelect={(player) => {
                setSelectedContext((prev) => {
                  return {
                    ...(prev || { version: "1" }),
                    other_player: {
                      id: player?.info?.id,
                      position: player?.info?.position,
                    },
                    other_competition: {
                      id: player?.competition?.id,
                      year: player?.competition?.year,
                    },
                  } as PlayerComparisonContext;
                });
                setOtherGender(player?.info?.gender);
              }}
            />
            <ExpandButton
              expanded={expanded[1]}
              onClick={() =>
                setExpanded(() => {
                  const newExpanded = [...expanded];
                  newExpanded[1] = !newExpanded[1];
                  return newExpanded;
                })
              }
            />
          </Stack>
        )}
        {selectedTask?.name === "redirect_to_transfer" && (
          <>
            <SelectPlayer
              variant="transfer"
              expanded={expanded[2]}
              defaultPlayer={preSelectedPlayer}
              onSelect={(player) => {
                setSelectedContext({
                  version: "1",
                  competition: {
                    id: player?.competition?.id,
                    year: player?.competition?.year,
                  },
                  player: {
                    id: player?.info?.id,
                    position: player?.info?.position,
                  },
                  quality: player?.quality?.quality,
                  other_competition: {
                    id: player?.other_competition?.id,
                    year: player?.other_competition?.year,
                  },
                } as PlayerTransferContext);
              }}
            />
            <ExpandButton
              expanded={expanded[2]}
              sx={{ marginTop: expanded[2] ? "-8px" : "-24px" }}
              onClick={() =>
                setExpanded(() => {
                  const newExpanded = [...expanded];
                  newExpanded[2] = !newExpanded[2];
                  return newExpanded;
                })
              }
            />
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "fit-content",
          }}
          disabled={!isValidSelectedContext()}
          onClick={() => {
            const data = {
              chat_function_name: selectedTask?.name!,
              context: selectedContext as any,
            };
            onChatFunctionSelected(data);
          }}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};

export default ScoutPlayerAnalystChatFunctionSelect;
