import {
  Container,
  ContainerProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  styled,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { darkModeApplicationPalette } from "../../themes/palettes";
import { ReactComponent as SvgLogo } from "../../shared/icons/earpiece12-logo.svg";

const SignInContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  backgroundColor: [theme.palette.primary.dark],
  width: "100%",
  height: "100%",
  padding: "0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    maxWidth: "482px",
  },
}));

const ButtonsContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  maxWidth: "400px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  [theme.breakpoints.up("md")]: {
    maxWidth: "420px",
    justifyContent: "space-between",
  },
}));

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: "280px",
  borderWidth: "1px",
  borderStyle: "solid",
}));

const SignIn = () => {
  const { loginWithPopup, isLoading } = useAuth0();
  const palette = darkModeApplicationPalette as any;

  return (
    <SignInContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SvgLogo style={{ maxWidth: "232px" }} />
        </Box>
        <ButtonsContainer>
          <StyledButton
            variant="contained"
            sx={{
              backgroundColor: palette.primary.main,
              borderColor: palette.background.paper,
              color: palette.primary.contrastText,
              "&:hover": {
                backgroundColor: palette.primary.dark,
              },
            }}
            component={"a"}
            href="https://twelve.football/earpiece"
          >
            Sign up to waiting list
          </StyledButton>
          <StyledButton
            variant="contained"
            sx={{
              borderColor: palette.background.paper,
              backgroundColor: palette.common.white,
              color: palette.primary.dark,
              "&:hover": {
                backgroundColor: palette.primary.dark,
                color: palette.primary.contrastText,
              },
            }}
            onClick={() => loginWithPopup()}
          >
            {isLoading ? "Logging in..." : "Log in"}
          </StyledButton>
        </ButtonsContainer>
      </Box>
    </SignInContainer>
  );
};

export default SignIn;
