import React, { createContext, useContext, useState, ReactNode } from "react";
import { ChatContextModel } from "../../models";

interface ChatContextProps {
  chatContext: ChatContextModel | undefined;
  setChatContext: (chatContext: ChatContextModel | undefined) => void;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

interface ChatContextProviderProps {
  children: ReactNode;
}

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
}) => {
  const [chatContext, setChatContext] = useState<ChatContextModel>();

  return (
    <ChatContext.Provider value={{ chatContext, setChatContext }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatContextProvider");
  }
  return context;
};
