import React, { ComponentType } from "react";
import { UserPermissions } from "./models";
import { useCurrentUser } from "./contexts/CurrentUserContext";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

interface ProtectedComponentProps {
  component: ComponentType<any>;
  [key: string]: any;
  permissions?: Array<UserPermissions> | UserPermissions;
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  component,
  permissions,
  ...args
}) => {
  const { currentUser } = useCurrentUser();
  const Component = withAuthenticationRequired(component, args);

  if (permissions?.length) {
    if (!currentUser?.chekPermissions(permissions)) {
      return <Navigate to="/forbidden" />;
    }
  }

  return <Component />;
};

export default ProtectedComponent;
