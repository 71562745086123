import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import styles from "../App.module.css";

function PublicApp() {
  return (
    <>
      <Box className={styles.main}>
        <Box
          className={styles.page}
          sx={{
            marginTop: 0,
            height: "100%",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default PublicApp;
