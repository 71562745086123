import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  ChatModel,
  FunctionCall,
  MessageContent,
  MessageModel,
  api,
} from "../../../api/twelve";
import InputArea from "./InputArea";
import { styled, Box, BoxProps, useTheme } from "@mui/material";
import PageLoader from "../../../shared/components/PageLoader";
import { MessageItem } from "./MessageItem";
import { useAuth0 } from "@auth0/auth0-react";
import { renameConversation } from "../../../store/conversationsReducer";
import { config } from "../../../config";
import { fetchSavedReports } from "../../reports/store/slices/savedReportShortListSlice";

const MainChatBox = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "18px 0",
  maxWidth: "800px",
  flexDirection: "column",
  gap: "18px",
}));

const ChatBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
}));

const MessagesBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100%",
  width: "100%",
  maxWidth: "800px",
  padding: "0 18px",
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
}));

function isRenderable(m: MessageModel): boolean {
  const content = m.content;
  if (m.role === "user" && content!.length > 0) {
    return true;
  } else if (m.plots && m.role !== "tool") {
    return true;
  } else if (m.role === "assistant" && content && content.length > 0) {
    return true;
  }
  return false;
}

export default function Chat({
  conversationId,
  chat,
  hasInput,
  firstMessage = undefined,
  functionСalls = undefined,
  onRedirect,
}: {
  conversationId: number;
  chat: ChatModel;
  hasInput: boolean;
  firstMessage?: string;
  functionСalls?: Array<FunctionCall>;
  onRedirect: (chatId: number) => void;
}) {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [generating, setGenerating] = useState(false);
  const dispatch = useDispatch();
  const selectedConversationName = useSelector(
    (state: RootState) => state.conversations.selectedConversation?.name,
  );
  const [chatId, setChatId] = useState<number>(chat.id);

  const processMessages = (
    items: Array<MessageModel> | null,
  ): Array<MessageModel> => {
    if (items === null || items === undefined) return [];

    return Array.from(
      items
        .filter(isRenderable)
        .sort((a, b) => Number(a.id) - Number(b.id))
        .reduce((map: Map<string, Array<MessageModel>>, m: MessageModel) => {
          const group_key = m.group_id || `${m.id}`;
          if (!map.has(group_key)) map.set(group_key, []);
          map.set(group_key, [...(map.get(group_key) || []), m]);
          return map;
        }, new Map<string, Array<MessageModel>>())
        .values(),
    ).map((messages): MessageModel => {
      const hasPlots = messages?.length > 1 && messages.every((m) => m.plots);
      return {
        id: messages[0].id || 0,
        role: messages[0].role,
        content: hasPlots ? "" : messages[messages.length - 1].content,
        chat_id: messages[0].chat_id || 0,
        plots: hasPlots
          ? messages.map((m) => ({
              url: m.plots[0]?.url,
              json_body: m.plots[0]?.json_body,
              description: m.content,
            }))
          : messages[0].plots,
        redirect: messages[0].redirect,
        disliked: messages[0].disliked,
        group_id: messages[0].group_id,
        report_public_id: messages[0].report_public_id,
        report_id: messages[0].report_id,
      };
    });
  };

  const onSend = useCallback(
    (message: MessageContent) => {
      setGenerating(true);
      const fn = async () => {
        let hasSavedReport: boolean | null = null;
        let newChatId: number | undefined = undefined;
        await api.addChatMessage(
          await getAccessTokenSilently(),
          conversationId,
          chatId,
          message,
          (chunks: MessageModel[][]) => {
            const newMessages = processMessages(chunks[0]);
            setMessages([...messages, ...newMessages]);

            if (newChatId === undefined) {
              newChatId = chunks[0].find((c) => c.chat_id !== chatId)?.chat_id;
              if (newChatId) {
                onRedirect(newChatId);
              }
            }
            if (hasSavedReport !== true) {
              hasSavedReport = chunks.reduce(
                (value, chunk) =>
                  value || chunk.some((a) => a.report_public_id),
                false,
              );
            }
          },
        );
        setGenerating(false);
        if (newChatId !== undefined) {
          setChatId(newChatId);
          dispatch(
            renameConversation(await getAccessTokenSilently(), conversationId),
          );
        }
        if (hasSavedReport) {
          dispatch(fetchSavedReports(await getAccessTokenSilently()));
        }
      };
      fn();
    },
    [
      chatId,
      conversationId,
      getAccessTokenSilently,
      dispatch,
      messages,
      onRedirect,
    ],
  );

  useEffect(() => {
    setMessages([]);
    setLoading(true);
    const loadFn = async () => {
      const chatModel = await api.showChat(
        await getAccessTokenSilently(),
        conversationId,
        chat.id,
      );
      if (chatModel.messages?.length) {
        setMessages(processMessages(chatModel.messages));
      } else {
        if (api.setChatInitialized(chat.id)) {
          onSend({ content: firstMessage || "" });
        }
      }
      setLoading(false);
    };

    loadFn();
    // todo: fix this eslint warning later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat.id, conversationId, firstMessage, getAccessTokenSilently]);

  useEffect(() => {
    if (!functionСalls) return;
    onSend({ function_calls: functionСalls, content: "" });
    // todo: fix this eslint warning later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionСalls]);

  const onUpdateDisliked = useCallback(
    async (messageId: number, disliked: boolean) => {
      await api.updateMessageDisliked(
        await getAccessTokenSilently(),
        conversationId,
        chatId,
        messageId,
        disliked,
      );
    },
    [chatId, conversationId, getAccessTokenSilently],
  );

  useEffect(() => {
    const renameConversationIfPossible = async () => {
      const userMessages = messages.filter((m) => m.role === "user");
      if (
        selectedConversationName === config.conversationNaming.default &&
        userMessages.length >= config.conversationNaming.userMessageThreshold
      ) {
        dispatch(
          renameConversation(await getAccessTokenSilently(), conversationId),
        );
      }
    };
    renameConversationIfPossible();
  }, [
    messages,
    conversationId,
    getAccessTokenSilently,
    dispatch,
    selectedConversationName,
  ]);

  return (
    <MainChatBox id={`chat-${chat.id}`}>
      <ChatBox>
        {loading ? (
          <PageLoader variant="inline" />
        ) : (
          <MessagesBox>
            {messages.map((m, i) => (
              <MessageItem
                key={`message_${m.id}`}
                chatId={chat.id}
                message={m}
                permaToolbar={i === messages.length - 1}
                onUpdateDisliked={onUpdateDisliked}
              />
            ))}
          </MessagesBox>
        )}
        {hasInput && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              width: "100%",
              bottom: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "18px",
              backgroundColor: theme.palette.background.default,
            }}
          >
            <InputArea
              onSend={(text: string) => {
                onSend({ content: text });
              }}
              loading={generating || !messages.length}
              disabled={!!loading}
            />
          </Box>
        )}
      </ChatBox>
    </MainChatBox>
  );
}
