import { useState } from "react";
import {
  styled,
  Box,
  Fab,
  Typography,
  useMediaQuery,
  useTheme,
  Snackbar,
  SnackbarContent,
  alpha,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ReplyIcon from "@mui/icons-material/Reply";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type DeviceType = "Android" | "iOS" | "Unknown";

const getDeviceType = (): DeviceType => {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return "Android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS";
  } else {
    return "Unknown";
  }
};

const Icon = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const deviceType = getDeviceType();

  if (!isMobile || deviceType === "Unknown")
    return <ReplyIcon sx={{ transform: "scaleX(-1)" }} />;

  if (deviceType === "iOS")
    return (
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0969 7.99977C15.5708 7.99977 15.9594 8.36473 15.9971 8.82892L16.0001 8.903V16.2579C16.0001 17.8588 14.7325 19.1637 13.1462 19.2235L13.0323 19.2256H2.96776C1.36683 19.2256 0.0619685 17.958 0.00214313 16.3717L0 16.2579V8.903C0 8.40416 0.40439 7.99977 0.903231 7.99977C1.37713 7.99977 1.76579 8.36473 1.80347 8.82892L1.80646 8.903V16.2579C1.80646 16.8725 2.28397 17.3756 2.88825 17.4165L2.96776 17.4192H13.0323C13.647 17.4192 14.1501 16.9417 14.191 16.3374L14.1936 16.2579V8.903C14.1936 8.40416 14.598 7.99977 15.0969 7.99977ZM7.74142 0.783148L7.81825 0.775456L7.90844 0.774643L7.98818 0.781357L8.08 0.798009L8.11561 0.807429C8.21642 0.835258 8.3133 0.88117 8.40127 0.944896L8.46296 0.99394L8.51093 1.03847L12.5255 5.05304C12.8782 5.40577 12.8782 5.97766 12.5255 6.3304C12.1935 6.66238 11.6674 6.68191 11.3126 6.38898L11.2481 6.3304L8.77424 3.85626V13.8063C8.77424 14.2802 8.40928 14.6688 7.94509 14.7065L7.87101 14.7095C7.39711 14.7095 7.00846 14.3445 6.97078 13.8803L6.96778 13.8063V3.85833L4.49636 6.3304C4.16438 6.66238 3.63826 6.68191 3.28343 6.38898L3.219 6.3304C2.88702 5.99841 2.86749 5.47229 3.16041 5.11747L3.219 5.05304L7.22007 1.05097C7.24891 1.021 7.2798 0.993014 7.31251 0.967241L7.371 0.925555C7.39047 0.911851 7.41084 0.899403 7.43172 0.88776L7.44142 0.883023C7.53282 0.832873 7.63407 0.798572 7.74142 0.783148Z"
          fill={theme.palette.common.white}
        />
      </svg>
    );
  return <ShareIcon />;
};

const StyledFab = styled(Fab)(({ theme }) => ({
  width: "56px",
  height: "56px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  fontSize: "12px",
  borderWidth: "1px",
  borderStyle: "solid",
  color: "white",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  background: alpha(theme.palette.common.white, 0.1),
  opacity: 0.8,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    opacity: 1,
  },
  "&:disabled": {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    color: "white",
    opacity: 0.8,
  },
}));

export default function ShareReportButton({
  reportPublicId,
  title,
  text,
}: {
  reportPublicId?: string;
  title?: string;
  text?: string;
}) {
  const theme = useTheme();
  const [isSharing, setIsSharing] = useState(false);
  const [copied, setCopied] = useState(false);

  const getShareUrl = () => {
    return `${window.location.origin}/shared-reports/${reportPublicId}`;
  };

  const handleShare = async () => {
    if (!navigator.share || isSharing) return;

    setIsSharing(true);

    try {
      await navigator.share({
        title: title,
        text: text,
        url: getShareUrl(),
      });
    } catch (err) {
    } finally {
      setIsSharing(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(getShareUrl());
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
      setCopied(false);
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 8,
        right: 8,
        zIndex: 9999,
        backdropFilter: "blur(10px)",
        borderRadius: "50%",
      }}
    >
      {navigator && typeof navigator.share === "function" ? (
        <StyledFab disabled={isSharing} onClick={handleShare}>
          <Icon />
          <Typography variant="body1" sx={{ fontSize: "12px" }}>
            Share
          </Typography>
        </StyledFab>
      ) : (
        <>
          <StyledFab onClick={handleCopy} disabled={copied}>
            <Icon />
            <Typography variant="body1" sx={{ fontSize: "12px" }}>
              Share
            </Typography>
          </StyledFab>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={copied}
          >
            <SnackbarContent
              message={
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <ContentCopyIcon fontSize="medium" />
                  <span>Link was copied to your clipboard</span>
                </Box>
              }
              sx={{
                borderRadius: "40px",
                border: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
                background: alpha(theme.palette.common.white, 0.3),
                backdropFilter: "blur(10px)",
              }}
            />
          </Snackbar>
        </>
      )}
    </Box>
  );
}
