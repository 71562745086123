import { Container, ContainerProps, styled } from "@mui/material";
import SignIn from "./SignIn";
// import ChatExamples from "./ChatExamples";

const MainContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  paddingLeft: "0px!important",
  paddingRight: "0px!important",
  display: "flex",
  width: "100vw",
  height: "calc(var(--vh, 1vh) * 100)",
  overflow: "hidden",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    maxWidth: "unset",
    backgroundColor: [theme.palette.primary.dark],
    color: [theme.palette.colors.accent],
  },
}));

export default function SignInPage() {
  return (
    <MainContainer>
      <SignIn />
      {/* <ChatExamples /> */}
    </MainContainer>
  );
}
