import React, { useCallback, useEffect, useRef, useState } from "react";
import { api } from "../../../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import useDebounce from "../../../../shared/hooks/useDebounce";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import {
  NoSearchResults,
  SearchBox,
  SearchBoxContainer,
  SearchDefaultText,
  SearchInput,
  SearchList,
  SearchListItemButton,
  SearchListItemTextMain,
  SearchListItemTextSecondary,
  SearchProgress,
} from "./SearchLayoutComponents";
import {
  SearchMatchInfo,
  SearchMatchesForTeamResult,
  SearchMatchesResult,
} from "../../../../models";
import {
  Box,
  Divider,
  IconButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DateTimeHelper from "../../../../shared/helpers/datetime.helper";

interface SearchMatchProps {
  open: boolean;
  onClose: () => void;
  onSelect: (match: SearchMatchInfo) => void;
}

const SearchMatchModal: React.FC<SearchMatchProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [matches, setMatches] = useState<SearchMatchesResult>({ matches: {} });
  const [selectedTeam, setSelectedTeam] = useState<
    { id: number; name: string; gender?: string } | undefined
  >();
  const [matchesForTeam, setMatchesForTeam] = useState<
    SearchMatchesForTeamResult | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [canLoadingMore, setCanLoadingMore] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const debouncedSearchTerm = useDebounce(searchQuery, 600);
  const abortControllerRef = useRef<AbortController | null>(null);
  const listContainerRef = useRef<any>(null);
  const limit: number = 15;
  const skip = useRef<number>(0);

  const searchMatchesForTeam = useCallback(async () => {
    if (selectedTeam) {
      setLoading(skip.current === 0);
      setLoadingMore(true);
      try {
        const result = await api.searchMatchesForTeam(
          await getAccessTokenSilently(),
          selectedTeam.id,
          skip.current,
          limit,
        );
        setCanLoadingMore(result.past_matches?.length === limit);
        setMatchesForTeam((prev) => {
          if (prev) {
            return {
              future_matches: result.future_matches || [],
              past_matches: [
                ...prev.past_matches,
                ...(result.past_matches || []),
              ],
            };
          }
          return result;
        });
      } catch {}
      setLoadingMore(false);
      setLoading(false);
    } else {
      skip.current = 0;
      setMatchesForTeam(undefined);
      setCanLoadingMore(true);
    }
  }, [getAccessTokenSilently, selectedTeam]);

  useEffect(() => {
    setSearchQuery("");
    setMatches({ matches: {} });
    setMatchesForTeam(undefined);
    setSelectedTeam(undefined);
  }, [open]);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const search = async () => {
      setLoading(true);
      try {
        setSelectedTeam(undefined);
        const result = await api.searchMatches(
          await getAccessTokenSilently(),
          debouncedSearchTerm,
          controller.signal,
        );
        if (result.matches && Object.keys(result.matches).length === 1) {
          const teamId = Object.keys(result.matches)[0];
          const [, { team_name, gender }] = Object.entries(result.matches)[0];
          if (teamId) {
            setSelectedTeam({
              id: Number(teamId),
              name: team_name,
              gender: gender,
            });
            return;
          }
        }
        setMatches(result);
      } catch {}
    };

    if (debouncedSearchTerm?.length < 3) {
      if (!debouncedSearchTerm.length) {
        setMatches({ matches: {} });
        setMatchesForTeam(undefined);
        setSelectedTeam(undefined);
      }
      return;
    }

    search();

    return () => {
      controller.abort();
    };
  }, [getAccessTokenSilently, debouncedSearchTerm]);

  useEffect(() => {
    setLoading(false);
  }, [matches]);

  useEffect(() => {
    searchMatchesForTeam();
  }, [selectedTeam, searchMatchesForTeam]);

  const onScroll = async () => {
    if (listContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 1 &&
        canLoadingMore &&
        !loadingMore
      ) {
        searchMatchesForTeam();
        skip.current += limit;
      }
    }
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      position="top"
    >
      <SearchBoxContainer>
        <SearchInput
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <SearchBox sx={{ overflow: `${loading ? "hidden" : "auto"}` }}>
          {loading ? (
            <SearchProgress />
          ) : debouncedSearchTerm.length ? (
            Object.keys(matches.matches).length > 0 || selectedTeam ? (
              <SearchList
                ref={listContainerRef}
                dense
                className="scrollable"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: selectedTeam ? 0 : "18px",
                }}
                onScroll={onScroll}
              >
                {selectedTeam ? (
                  matchesForTeam ? (
                    <>
                      <ListSubheader
                        disableSticky
                        sx={{
                          background: "transparent",
                          display: "flex",
                        }}
                      >
                        <SearchListItemTextSecondary
                          secondary={
                            selectedTeam.name +
                            (selectedTeam.gender === "male"
                              ? " | Mens"
                              : selectedTeam.gender === "female"
                              ? " | Womens"
                              : "")
                          }
                        />
                      </ListSubheader>
                      {[
                        ...(matchesForTeam?.future_matches || []).sort((a, b) =>
                          new Date(a.scheduled_start) <
                          new Date(b.scheduled_start)
                            ? 1
                            : -1,
                        ),
                        undefined,
                        ...(matchesForTeam?.past_matches || []),
                      ].map((match, index) =>
                        match ? (
                          <SearchListItemButton
                            key={`team-match-${match.match_id}`}
                            onClick={() => {
                              onSelect(match);
                              onClose();
                            }}
                          >
                            <Typography
                              fontSize={"12px"}
                              color={"text.disabled"}
                              textAlign={"left"}
                              width={"100%"}
                            >
                              {match.competition_name}
                            </Typography>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <SearchListItemTextMain
                                primary={`${match.home_team_name}${
                                  match.home_team_score != null
                                    ? ` ${match.home_team_score}`
                                    : ""
                                } - ${
                                  match.away_team_score != null
                                    ? `${match.away_team_score} `
                                    : ""
                                }${match.away_team_name}`}
                              />
                              <SearchListItemTextSecondary
                                sx={{ textAlign: "right" }}
                                secondary={`${DateTimeHelper.format(
                                  match.scheduled_start,
                                  "D/M-YYYY",
                                )}`}
                              />
                            </Box>
                          </SearchListItemButton>
                        ) : matchesForTeam?.future_matches.length ? (
                          <Divider key={`divider-${index}`}>
                            <Typography
                              fontSize={"12px"}
                              color={"text.disabled"}
                            >
                              Today
                            </Typography>
                          </Divider>
                        ) : (
                          <></>
                        ),
                      )}
                    </>
                  ) : (
                    <SearchProgress />
                  )
                ) : (
                  Object.entries(matches.matches).map(
                    (
                      [key, { team_name, gender, next_match, previous_match }],
                      index,
                    ) => (
                      <Box key={`match-${index}`}>
                        <ListSubheader
                          disableSticky
                          sx={{
                            background: "transparent",
                            display: "flex",
                          }}
                        >
                          <SearchListItemTextSecondary
                            secondary={
                              team_name +
                              (gender === "male"
                                ? " | Mens"
                                : gender === "female"
                                ? " | Womens"
                                : "")
                            }
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSelectedTeam({
                                id: Number(key),
                                name: team_name,
                                gender: gender,
                              });
                            }}
                          >
                            <ArrowOutwardIcon
                              fontSize="small"
                              sx={{
                                transform: "rotate(-90deg)",
                              }}
                            />
                          </IconButton>
                        </ListSubheader>
                        {next_match && (
                          <SearchListItemButton
                            onClick={() => {
                              onSelect(next_match);
                              onClose();
                            }}
                          >
                            <Typography
                              fontSize={"12px"}
                              color={"text.disabled"}
                              textAlign={"left"}
                              width={"100%"}
                            >
                              {next_match.competition_name}
                            </Typography>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <SearchListItemTextMain
                                primary={`${next_match.home_team_name} - ${next_match.away_team_name}`}
                              />
                              <SearchListItemTextSecondary
                                sx={{ textAlign: "right" }}
                                secondary={`${DateTimeHelper.format(
                                  next_match.scheduled_start,
                                  "D/M-YYYY",
                                )}`}
                              />
                            </Box>
                          </SearchListItemButton>
                        )}
                        {previous_match && (
                          <SearchListItemButton
                            onClick={() => {
                              onSelect(previous_match);
                              onClose();
                            }}
                          >
                            <Typography
                              fontSize={"12px"}
                              color={"text.disabled"}
                              textAlign={"left"}
                              width={"100%"}
                            >
                              {previous_match.competition_name}
                            </Typography>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <SearchListItemTextMain
                                primary={`${previous_match.home_team_name} ${
                                  previous_match.home_team_score ?? ""
                                } - ${previous_match.away_team_score ?? ""} ${
                                  previous_match.away_team_name
                                }`}
                              />
                              <SearchListItemTextSecondary
                                sx={{ textAlign: "right" }}
                                secondary={`${DateTimeHelper.format(
                                  previous_match.scheduled_start,
                                  "D/M-YYYY",
                                )}`}
                              />
                            </Box>
                          </SearchListItemButton>
                        )}
                      </Box>
                    ),
                  )
                )}
              </SearchList>
            ) : (
              <NoSearchResults text="No matches found" />
            )
          ) : (
            <SearchDefaultText text="Find matches by searching for home and/or away team." />
          )}
        </SearchBox>
      </SearchBoxContainer>
    </ResponsiveDialog>
  );
};

export default SearchMatchModal;
