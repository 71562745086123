import React from "react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TemplatePropertyIcon from "./TemplatePropertyIcon";
import { ReportTemplateProperty } from "../../models";
import StringHelper from "../../../../shared/helpers/string.helper";

function DesktopMenu({
  properties,
  onSelect,
}: {
  properties: Array<ReportTemplateProperty>;
  onSelect: (property: ReportTemplateProperty) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (property: ReportTemplateProperty) => {
    onSelect(property);
    handleClose();
  };

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        size="small"
        startIcon={<AddIcon />}
        id="select-template-props-button"
        aria-controls={open ? "select-template-props-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Add property
      </Button>
      <Menu
        id="select-template-props-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "200px",
            elevation: 1,
          },
        }}
      >
        {properties.map((property, index) => (
          <MenuItem
            key={`property-${index}`}
            onClick={() => handleSelect(property)}
          >
            <ListItemIcon>
              <TemplatePropertyIcon property={property} />
            </ListItemIcon>
            {StringHelper.normalizeKey(property.type)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function MobileMenu({
  properties,
  onSelect,
}: {
  properties: Array<ReportTemplateProperty>;
  onSelect: (property: ReportTemplateProperty) => void;
}) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };
  const Puller = styled("div")(({ theme }) => ({
    width: 32,
    height: 4,
    backgroundColor: theme.palette.common.white,
    opacity: 0.1,
    borderRadius: 2,
    position: "absolute",
    top: 8,
    left: "calc(50% - 16px)",
  }));
  return (
    <>
      <Button
        variant="text"
        color="inherit"
        size="small"
        startIcon={<AddIcon />}
        onClick={toggleDrawer(true)}
      >
        Add property
      </Button>
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          elevation: 1,
          sx: {
            borderRadius: "16px 16px 0 0",
          },
        }}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          sx={{
            padding: "32px 0 8px 0",
            borderRadius: "16px",
          }}
        >
          <Puller />
          <Typography
            variant="subtitle2"
            align="center"
            sx={{
              marginBottom: "16px",
              fontWeight: 600,
              fontSize: "16px",
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            Properties
          </Typography>
          <Divider />
          <List dense>
            {properties.map((property, index) => (
              <ListItemButton
                key={`property-${index}`}
                onClick={() => onSelect(property)}
              >
                <ListItemIcon sx={{ minWidth: "32px" }}>
                  <TemplatePropertyIcon
                    property={property}
                    color="text.primary"
                  />
                </ListItemIcon>
                <ListItemText>
                  {StringHelper.normalizeKey(property.type)}
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default function SelectTemplateProperties({
  onSelect,
}: {
  onSelect: (property: ReportTemplateProperty) => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const properties: Array<ReportTemplateProperty> = [
    { type: "player" },
    { type: "match" },
    { type: "team" },
    // { type: "tags" },
  ];

  return (
    <>
      {isMobile ? (
        <MobileMenu properties={properties} onSelect={onSelect} />
      ) : (
        <DesktopMenu properties={properties} onSelect={onSelect} />
      )}
    </>
  );
}
