import React, { useState, useRef, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Fab, Stack, useMediaQuery, useTheme } from "@mui/material";
import Chat from "./chat/Chat";
import { api, ChatModel, FunctionCall } from "../../api/twelve";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { config } from "../../config";
import { renameConversation } from "../../store/conversationsReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import AnalystPane from "./analyst-pane/AnalystPane";
import { AnalystEnum } from "../../models";
import {
  setAnalystPaneMobileHeader,
  toggleAnalystPane,
  toggleSidebar,
} from "../../store/viewReducer";
import { ChatContextProvider, useChatContext } from "./ChatContext";

function Conversation() {
  const params = useParams<{ conversationId: string }>();
  const [conversationId, setConversationId] = useState(0);
  const [data, setData] = useState<{
    conversationId: number;
    chats: ChatModel[];
  }>({ conversationId: 0, chats: [] });
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const selectedConversationName = useSelector(
    (state: RootState) => state.conversations.selectedConversation?.name,
  );
  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const endRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const { chatContext, setChatContext } = useChatContext();

  const [functionCall, setFunctionCall] = useState<FunctionCall[] | undefined>(
    undefined,
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isBelow1200px = useMediaQuery(theme.breakpoints.down(1200));

  const fetchData = useCallback(async () => {
    if (!conversationId) return;
    const token = await getAccessTokenSilently();
    const chats = await api.listChats(token, conversationId);
    setData({ conversationId, chats });
  }, [conversationId, getAccessTokenSilently]);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setShowScrollButton(scrollTop < scrollHeight - clientHeight - 1);
    }
  };

  useEffect(() => {
    setConversationId(Number(params.conversationId));
  }, [params.conversationId]);

  useEffect(() => {
    fetchData();
    setShowScrollButton(false);
  }, [fetchData]);

  useEffect(() => {
    const renameConversationIfPossible = async () => {
      if (selectedConversationName === config.conversationNaming.default) {
        if (data.chats.filter((c) => c.analyst_name === "SCOUT")?.length > 0) {
          dispatch(
            renameConversation(
              await getAccessTokenSilently(),
              data.conversationId,
            ),
          );
        }
      }
    };
    renameConversationIfPossible();
  }, [selectedConversationName, data, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (scrollRef.current && !showScrollButton) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    });

    if (scrollRef.current) {
      observer.observe(scrollRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [scrollRef, showScrollButton]);

  useEffect(() => {
    if (data.chats.length > 0) {
      const lastChat = data.chats[data.chats.length - 1];
      setChatContext({
        analyst: lastChat.analyst_name as AnalystEnum,
        context: lastChat.context,
      });
    }
  }, [data, setChatContext]);

  useEffect(() => {
    if (
      !chatContext ||
      ![
        AnalystEnum.SCOUT,
        AnalystEnum.COMPARISON,
        AnalystEnum.TRANSFER,
      ].includes(chatContext.analyst)
    ) {
      dispatch(toggleAnalystPane(false));
      return;
    }
    dispatch(toggleAnalystPane(!isMobile));

    return () => {
      dispatch(setAnalystPaneMobileHeader(undefined));
    };
  }, [chatContext, isMobile, dispatch]);

  useEffect(() => {
    if (isBelow1200px) {
      dispatch(toggleSidebar(false));
    }
  }, [isBelow1200px, dispatch]);

  return (
    <Stack direction={"row"}>
      <Stack position={"relative"} width={"100%"} height={"100%"}>
        <Box
          className="scrollable"
          sx={{
            width: "100%",
            height: "calc(100% - 92px)",
            overflowX: "clip",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
          }}
          ref={scrollRef}
          onScroll={onScroll}
        >
          {data.chats.map((c: ChatModel, i: number) => {
            const hasInput = i >= data.chats.length - 1;
            return (
              <Chat
                key={c.id}
                conversationId={data.conversationId}
                chat={c}
                hasInput={hasInput}
                firstMessage={
                  location.state && c.id === location.state.chatId
                    ? location.state.firstMessage
                    : undefined
                }
                onRedirect={async (chatId: number) => {
                  console.log("Redirecting to chat", chatId);
                  const newChat = await api.showChat(
                    await getAccessTokenSilently(),
                    conversationId,
                    chatId,
                  );
                  setChatContext({
                    analyst: newChat.analyst_name as AnalystEnum,
                    context: newChat.context,
                  });
                }}
                functionСalls={hasInput ? functionCall : undefined}
              />
            );
          })}
          <div ref={endRef} />
        </Box>

        {showScrollButton && (
          <Fab
            size="small"
            sx={{
              position: "absolute",
              bottom: "100px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            onClick={() => {
              if (endRef.current) {
                endRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            <ArrowDownwardIcon />
          </Fab>
        )}
      </Stack>
      <AnalystPane
        onChatFunctionSelected={async (selection) => {
          setFunctionCall([
            {
              name: selection.chat_function_name,
              arguments: selection.context,
            },
          ]);
        }}
      />
    </Stack>
  );
}

export default function ConversationPage() {
  return (
    <ChatContextProvider>
      <Conversation />
    </ChatContextProvider>
  );
}
