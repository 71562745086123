import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../store/viewReducer";
import {
  Box,
  BoxProps,
  IconButton,
  Typography,
  styled,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  Divider,
  SwipeableDrawer,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { RootState } from "../store";
import { useAuth0 } from "@auth0/auth0-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import SelectTheme from "../shared/components/SelectTheme";
import { ConversationList } from "../sections/conversation/ConversationList";
import ReportTemplatesList from "../sections/reports/templates/components/ReportTemplatesList";
import { useCurrentUser } from "../sections/auth/contexts/CurrentUserContext";
import { UserPermissions } from "../sections/auth/models";

const Main = styled(Box)<BoxProps>(({ theme }) => ({
  position: "relative",
  borderRight: `1px solid ${theme.palette.divider}`,
  width: "256px",
  height: "calc(var(--vh, 1vh) * 100 - 64px)",
  top: "64px",
  background: theme.palette.backgrounds.primary,
  zIndex: 10,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    height: "calc(var(--vh, 1vh) * 100 - 56px)",
    top: "56px",
  },
}));

const Footer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  gap: "14px",
  height: "72px",
  padding: "16px",
  width: "100%",
  backgroundColor: theme.palette.backgrounds.secondary,
  [theme.breakpoints.down("sm")]: {},
}));

function MenuContent({ isMobile = false }: { isMobile?: boolean }) {
  const theme = useTheme();
  const { logout } = useAuth0();

  return (
    <Box
      sx={{
        minWidth: "160px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          padding: "4px 16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "8px",
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.text.disabled,
            fontSize: "12px",
            fontWeight: 700,
            letterSpacing: " 0.4px",
            width: "100%",
          }}
        >
          Appearance
        </Typography>
        <SelectTheme />
      </Box>
      <Divider sx={{ margin: `${isMobile ? "16px" : "8px"} 0` }} />
      <MenuItem
        sx={{
          color: theme.palette.primary.main,
          fontSize: "13px",
          fontWeight: 500,
          justifyContent: isMobile ? "center" : "left",
        }}
        onClick={() => {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }}
      >
        Log out
      </MenuItem>
    </Box>
  );
}

function DesktopMenu() {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpened = Boolean(menuAnchorEl);

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={menuOpened ? "long-menu" : undefined}
        aria-expanded={menuOpened ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event: any) => {
          setMenuAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpened}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        onClick={() => {
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Fade}
      >
        <MenuContent />
      </Menu>
    </>
  );
}

function MobileMenu() {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      dispatch(toggleSidebar(false));
      setDrawerOpen(open);
    };
  const Puller = styled("div")(({ theme }) => ({
    width: 32,
    height: 4,
    backgroundColor: theme.palette.common.white,
    opacity: 0.1,
    borderRadius: 2,
    position: "absolute",
    top: 8,
    left: "calc(50% - 16px)",
  }));

  return (
    <>
      <IconButton onClick={toggleDrawer(true)}>
        <MoreVertIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          elevation: 1,
          sx: {
            borderRadius: "16px 16px 0 0",
          },
        }}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          sx={{
            padding: "32px 0 16px 0",
            borderRadius: "16px",
          }}
        >
          <Puller />
          <MenuContent isMobile={true} />
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default function Sidebar() {
  const opened = useSelector((state: RootState) => state.view.sidebar.opened);
  const section = useSelector((state: RootState) => state.view.section);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useAuth0();
  const { currentUser } = useCurrentUser();

  useState(() => {
    if (isMobile) dispatch(toggleSidebar(false));
  });

  useEffect(() => {
    if (
      section !== "conversation" &&
      !currentUser?.chekPermissions(UserPermissions.CRAETE_SCOUT_REPORT)
    ) {
      dispatch(toggleSidebar(false));
    }
  }, [section, currentUser, dispatch]);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          zIndex: 1100,
          display: isMobile && opened ? "block" : "none",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        onClick={() => dispatch(toggleSidebar(false))}
      />
      <Box
        sx={{
          flexShrink: 0,
          position: "static",
          zIndex: 1200,
          transform: "unset",
          marginLeft: opened ? "0" : "-256px",
          transition: theme.transitions.create(["all"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          [theme.breakpoints.down(1200)]: {
            position: "fixed",
            marginLeft: "unset",
            transform: opened ? "translateX(0)" : "translateX(-100%)",
          },
        }}
      >
        <Main component="nav">
          <Box
            sx={{
              flexGrow: 1,
              overflow: "hidden",
            }}
            onClick={() => {
              if (isMobile) {
                dispatch(toggleSidebar());
              }
            }}
          >
            {section === "conversation" &&
              currentUser?.chekPermissions(UserPermissions.READ_CHAT) && (
                <ConversationList key="conversations" />
              )}
            {section === "reports" &&
              currentUser?.chekPermissions(
                UserPermissions.CREATE_SCOUT_TEMPLATE,
              ) && <ReportTemplatesList key="report-templates" />}
          </Box>
          <Footer>
            <Avatar src={user?.picture} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                justifyContent: "center",
                color: "text.primary",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "120px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {user?.nickname}
              </Typography>
            </Box>
            {useMediaQuery(theme.breakpoints.down("sm")) ? (
              <MobileMenu />
            ) : (
              <DesktopMenu />
            )}
          </Footer>
        </Main>
      </Box>
    </>
  );
}
