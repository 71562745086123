import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import { ReactComponent as TwelveIcon } from "../../../../shared/icons/twelve-logo.svg";
import { PlayerEvaluationContext } from "../../../../models";
import React, { useEffect, useState } from "react";
import { api } from "../../../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import StringHelper from "../../../../shared/helpers/string.helper";
import PageLoader from "../../../../shared/components/PageLoader";
import {
  StyledTab,
  StyledTabs,
  StyledTwelveIcon,
} from "../common-components/StyledComponents";
import InfoCard from "../common-components/InfoCard";
import { useDispatch } from "react-redux";
import { setAnalystPaneMobileHeader } from "../../../../store/viewReducer";
import { ReportOnTemplate, ReportTemplate } from "../../../reports/models";
import DateTimeHelper from "../../../../shared/helpers/datetime.helper";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import ReportForm from "../../../reports/templates/components/ReportForm";
import { useCurrentUser } from "../../../auth/contexts/CurrentUserContext";
import { UserPermissions } from "../../../auth/models";
import SelectTemplate from "../../../reports/templates/components/SelectTemplate";
import CompetitionHelper from "../../../../shared/helpers/competition.helper";
import { BaseAnalystPaneProps } from "../common-components/BaseAnalystPane";
import { useChatContext } from "../../ChatContext";

const ScoutPlayerAnalystPane: React.FC<BaseAnalystPaneProps> = () => {
  const { chatContext } = useChatContext();
  const context = chatContext?.context as PlayerEvaluationContext;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getAccessTokenSilently } = useAuth0();
  const { currentUser } = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  const [playerInfo, setPlayerInfo] = useState<any>();
  const [playerStatsInfo, setPlayerStatsInfo] = useState<any>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [reports, setReports] = useState<ReportOnTemplate[]>([]);
  const [groupedReports, setGroupedReports] = useState<
    Record<
      string,
      {
        template: { id: number; name: string };
        reports: Array<ReportOnTemplate>;
      }
    >
  >({});
  const [openDialog, setOpenDialog] = useState(false);
  const emptyReport: ReportOnTemplate = {
    id: 0,
    user_id: currentUser?.id,
    name: "",
    description: "",
    template_id: 0,
    content: {
      properties: [],
      sections: [],
    },
  };
  const [report, setReport] = useState<ReportOnTemplate>(emptyReport);

  useEffect(() => {
    if (!context) return;

    setSelectedTab(0);
    const getPlayerSeasonInfo = async () => {
      if (!context?.player?.id) return;
      setLoading(true);
      const token = await getAccessTokenSilently();
      const currentPlayerCompetition = (
        await api.getPlayerCareerInfo(token, context.player.id)
      )?.find((c) => c.current);
      const data = await api.getPlayerSeasonInfo(
        token,
        context.player.id,
        currentPlayerCompetition?.id || context.competition.id,
        currentPlayerCompetition?.year || context.competition.year,
        context.player.position,
      );
      setPlayerInfo(data);
      const dataStats = await api.getPlayerSeasonInfo(
        token,
        context.player.id,
        context.competition.id,
        context.competition.year,
        context.player.position,
      );
      setPlayerStatsInfo(dataStats);
      setLoading(false);
    };
    getPlayerSeasonInfo();
  }, [context, getAccessTokenSilently]);

  useEffect(() => {
    if (isMobile && playerInfo) {
      dispatch(
        setAnalystPaneMobileHeader({
          title: playerInfo?.synthesized_name,
          logo: playerInfo?.image_url,
        }),
      );
    }
  }, [playerInfo, isMobile, dispatch]);

  useEffect(() => {
    const getPlayerReports = async () => {
      if (!context?.player.id) return;

      setLoadingReports(true);
      const reports = await api.getReportOnTemplatesByPlayerId(
        await getAccessTokenSilently(),
        context?.player.id,
      );
      setLoadingReports(false);

      setReports(reports);
    };

    if (selectedTab === 1 && context?.player.id) {
      getPlayerReports();
    }
  }, [selectedTab, context, getAccessTokenSilently]);

  useEffect(() => {
    const groupedItems = reports.reduce((acc, item) => {
      if (!acc[item.template_id]) {
        acc[item.template_id] = {
          template: {
            id: item.template_id,
            name: item.template_name || "",
          },
          reports: [],
        };
      }
      acc[item.template_id].reports.push(item);
      return acc;
    }, {} as Record<string, { template: { id: number; name: string }; reports: Array<ReportOnTemplate> }>);

    setGroupedReports(groupedItems);
  }, [reports]);

  return loading ? (
    <PageLoader variant="full" />
  ) : (
    <>
      <Stack spacing={2} sx={{ height: "100%" }}>
        <Paper
          elevation={0}
          sx={{
            borderRadius: 0,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Stack spacing={2} padding={"24px"}>
            <Stack direction={"row"} spacing={2}>
              <Avatar
                src={playerInfo?.image_url}
                sx={{ width: "64px", height: "64px" }}
              />
              <Stack
                direction={"column"}
                spacing={1}
                justifyContent={"flex-end"}
                flexGrow={1}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.text.primary,
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  {playerInfo?.synthesized_name}
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.disabled,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {playerInfo?.team.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.text.disabled,
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {StringHelper.normalizeKey(playerInfo?.position)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {/* <Box>
            <Button variant="text" endIcon={<AddIcon />} disabled>
              Add to shortlist
            </Button>
          </Box> */}
          </Stack>
          {currentUser?.chekPermissions([
            UserPermissions.READ_ALL_SCOUT_REPORTS,
            UserPermissions.READ_OWN_SCOUT_REPORTS,
          ]) ? (
            <StyledTabs
              value={selectedTab}
              onChange={(event: React.SyntheticEvent, tab: number) => {
                setSelectedTab(tab);
              }}
              variant="fullWidth"
              sx={{
                marginTop: "-16px",
              }}
            >
              <StyledTab
                label="Overview"
                iconPosition="start"
                icon={<StyledTwelveIcon component={TwelveIcon} />}
              />
              <StyledTab
                label="Notes"
                iconPosition="start"
                icon={<NotesIcon />}
              />
            </StyledTabs>
          ) : (
            <></>
          )}
        </Paper>
        {selectedTab === 0 && (
          <Stack spacing={"32px"} sx={{ padding: "24px" }}>
            <InfoCard
              title="Info"
              items={[
                { key: "Club", value: playerInfo?.team.name },
                { key: "Nationality", value: playerInfo?.nationality },
                { key: "Date of birth", value: playerInfo?.birth_date },
                {
                  key: "Strong foot",
                  value: StringHelper.normalizeKey(playerInfo?.foot),
                },
                {
                  key: "Agencies",
                  value: playerInfo?.agencies?.split(",")?.join(", "),
                },
                {
                  key: "Estimated value",
                  value: StringHelper.formatCurrency(
                    playerInfo?.transfermarkt_value,
                  ),
                },
              ]}
            />
            <InfoCard
              title={CompetitionHelper.getCompetitionSeasonString(
                playerStatsInfo?.competition,
              )}
              items={[
                {
                  key: "Minutes played",
                  value: playerStatsInfo?.minutes_played,
                },
                { key: "Matches", value: playerStatsInfo?.matches },
                {
                  key: "Matches from start",
                  value: playerStatsInfo?.matches_from_starts,
                },
                { key: "Goals", value: playerStatsInfo?.goals },
                { key: "Assists", value: playerStatsInfo?.assists },
                {
                  key: "Yellow cards",
                  value: playerStatsInfo?.yellow_cards,
                },
                {
                  key: "Red cards",
                  value: playerStatsInfo?.red_cards,
                },
              ]}
            />
          </Stack>
        )}
        {selectedTab === 1 && (
          <Stack spacing={"32px"} sx={{ padding: "24px" }}>
            {loadingReports ? (
              <PageLoader variant="full" sx={{ height: "100%" }} />
            ) : (
              <>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 400,
                      color: "text.primary",
                    }}
                  >
                    Reports
                  </Typography>
                  {currentUser?.chekPermissions(
                    UserPermissions.CRAETE_SCOUT_REPORT,
                  ) && (
                    <SelectTemplate
                      filter={(template) =>
                        template.structure.properties.some(
                          (property) => property.type === "player",
                        )
                      }
                      onSelect={(_template) => {
                        const template = structuredClone(_template);
                        const reportOnTemplate = {
                          id: 0,
                          user_id: currentUser?.id,
                          name: "",
                          description: template.description,
                          template_id: template.id,
                          content: template.structure,
                        };
                        const property = (
                          template as ReportTemplate
                        ).structure.properties.find(
                          (property) => property.type === "player",
                        );
                        if (property) {
                          property.data = [
                            {
                              id: playerInfo?.id,
                              value: playerInfo?.synthesized_name,
                            },
                          ];
                        }
                        setReport(reportOnTemplate);
                        setOpenDialog(true);
                      }}
                    />
                  )}
                </Stack>
                {Object.keys(groupedReports).map((key, index) => (
                  <Stack key={`report-group-${index}`} spacing={1}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "text.secondary",
                        }}
                      >
                        {groupedReports[key].template.name}
                      </Typography>
                    </Box>
                    <List
                      sx={{
                        borderRadius: "8px",
                        background: theme.palette.background.paper,
                      }}
                      elevation={1}
                      component={Paper}
                    >
                      {groupedReports[key].reports.map((report, index) => (
                        <React.Fragment key={`report-item-${index}`}>
                          <ListItemButton
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "16px",
                              padding: "16px 12px",
                              textDecoration: "none",
                            }}
                            onClick={async () => {
                              const reportOnTemplate =
                                await api.getReportOnTemplateById(
                                  await getAccessTokenSilently(),
                                  report.id,
                                );
                              setReport(reportOnTemplate);
                              setOpenDialog(true);
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "8px",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "text.disabled",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  textWrap: "nowrap",
                                }}
                              >
                                {report.template_name}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color: "text.disabled",
                                }}
                              >
                                {DateTimeHelper.format(
                                  report.created_at,
                                  "DD/M - YYYY",
                                )}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              {report.content?.properties.length ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "4px",
                                    flexDirection: "column",
                                  }}
                                >
                                  {report?.content.properties.map(
                                    (property, propertyIndex) => (
                                      <Box
                                        key={`property-${propertyIndex}`}
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: "4px",
                                          fontWeight:
                                            propertyIndex === 0 ? 700 : 400,
                                          color:
                                            propertyIndex === 0
                                              ? "text.primary"
                                              : "text.secondary",
                                        }}
                                      >
                                        {property.data?.map(
                                          (propertData, propertyDataIndex) => (
                                            <Typography
                                              key={`property-data-${propertyDataIndex}`}
                                              sx={{
                                                fontSize: "14px",
                                              }}
                                            >
                                              {propertData.value}
                                              {propertyDataIndex <
                                              property?.data?.length! - 1 ? (
                                                <>, </>
                                              ) : (
                                                <></>
                                              )}
                                            </Typography>
                                          ),
                                        )}
                                      </Box>
                                    ),
                                  )}
                                </Box>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    color: "text.primary",
                                    textDecoration: "unset",
                                  }}
                                >
                                  {report.name}
                                </Typography>
                              )}
                              {report.user_name?.length ||
                              report.user_name?.length ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      width: "24px",
                                      height: "24px",
                                      fontSize: "12px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {`${
                                      report.user_name
                                        ? report.user_name[0]
                                        : ""
                                    }${
                                      report.user_surname
                                        ? report.user_surname[0]
                                        : ""
                                    }`}
                                  </Avatar>
                                </Box>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </ListItemButton>
                          {index + 1 < groupedReports[key].reports?.length ? (
                            <Divider />
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                    </List>
                  </Stack>
                ))}
              </>
            )}
          </Stack>
        )}
      </Stack>

      <ResponsiveDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setReport(emptyReport);
        }}
        maxWidth="sm"
        position="center"
        sx={{
          background: theme.palette.background.default,
          padding: `${isMobile ? 0 : "0px 12px"}`,
        }}
      >
        <ReportForm
          variant={report.id === 0 ? "create" : "update"}
          reportOnTemplate={report}
          onCreated={(newReport) => {
            setReports((prevReports) => {
              return [newReport, ...prevReports];
            });
            setOpenDialog(false);
            setReport(emptyReport);
          }}
          onUpdated={(updatedReport) => {
            setReports((prevReports) => {
              return prevReports.map((prevReport) =>
                prevReport.id === updatedReport.id ? updatedReport : prevReport,
              );
            });
            setOpenDialog(false);
            setReport(emptyReport);
          }}
          onDeleted={(deletedReportId) => {
            setReports((prevReports) => {
              return prevReports.filter(
                (prevReport) => prevReport.id !== deletedReportId,
              );
            });
            setOpenDialog(false);
            setReport(emptyReport);
          }}
          onDuplicated={(duplicatedReport) => {
            setReports((prevReports) => {
              return [duplicatedReport, ...prevReports];
            });
            setOpenDialog(false);
            setReport(emptyReport);
          }}
          onCanceled={() => {
            setOpenDialog(false);
            setReport(emptyReport);
          }}
        />
      </ResponsiveDialog>
    </>
  );
};

export default ScoutPlayerAnalystPane;
