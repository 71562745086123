import { alpha, Box, Link, Typography, useTheme } from "@mui/material";

export default function SharedReportFooter() {
  const theme = useTheme();
  return (
    <Link
      href={"https://twelve.football/earpiece"}
      style={{ textDecoration: "none" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          height: "56px",
          width: "100%",
          zIndex: 9999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          fontSize: "16px",
          borderTop: `0.5px solid ${alpha(theme.palette.common.white, 0.2)}`,
          background: alpha(theme.palette.common.white, 0.1),
          boxShadow: `0px 1px 1px 0px ${alpha(
            theme.palette.common.black,
            0.2,
          )}`,
          backdropFilter: "blur(10px)",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: "0px",
            fontSize: "14px",
          },
        }}
      >
        <Typography
          sx={{
            color: theme.palette.common.white,
          }}
        >
          Look up any player in the world.
        </Typography>
        <Typography
          sx={{
            color: theme.palette.customColors.accent,
            textDecoration: "underline",
          }}
        >
          Try Earpiece on WhatsApp for free.
        </Typography>
      </Box>
    </Link>
  );
}
