import {
  Box,
  BoxProps,
  Divider,
  styled,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from "@mui/material";

interface BottomSwipeableDrawerProps extends SwipeableDrawerProps {
  title?: string;
  fullHeight?: boolean;
  sx?: any;
}

const Puller = styled(Box)<BoxProps>(({ theme }) => ({
  width: 32,
  height: 4,
  backgroundColor: theme.palette.common.white,
  opacity: 0.1,
  borderRadius: 2,
  position: "absolute",
  top: 8,
  left: "calc(50% - 16px)",
}));

const BottomSwipeableDrawer: React.FC<BottomSwipeableDrawerProps> = ({
  children,
  title,
  fullHeight = false,
  sx = {},
  ...rest
}: BottomSwipeableDrawerProps) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      className="scrollable"
      PaperProps={{
        sx: {
          height: fullHeight ? "100%" : "auto",
          ...sx,
        },
      }}
      {...rest}
    >
      <Puller />
      <Box role="presentation">
        {title ? (
          <Box>
            <Typography
              variant="subtitle2"
              align="center"
              sx={{
                margin: "16px 0",
                fontWeight: 600,
                fontSize: "16px",
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              {title}
            </Typography>
            <Divider />
          </Box>
        ) : (
          <></>
        )}
        <Box>{children}</Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default BottomSwipeableDrawer;
