import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import styles from "../App.module.css";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";
import ConversationStartingScreen from "../sections/conversation/ConversationStartingScreen";
import {
  CurrentUserProvider,
  useCurrentUser,
} from "../sections/auth/contexts/CurrentUserContext";
import PageLoader from "../shared/components/PageLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { setSection } from "../store/viewReducer";
import SignInPage from "../sections/auth/SignInPage";
import { UserPermissions } from "../sections/auth/models";

const MainComponent: React.FC = React.memo(() => {
  const { currentUser } = useCurrentUser();
  const location = useLocation();

  if (!currentUser) return <PageLoader variant="full" />;

  return (
    <>
      <TopBar />
      <Box className={styles.main}>
        <Box>
          <Sidebar />
        </Box>
        <Box
          className={styles.page}
          sx={{
            backgroundColor: "background.default",
          }}
        >
          {location.pathname === "/" ? (
            currentUser?.chekPermissions(UserPermissions.READ_CHAT) ? (
              <ConversationStartingScreen />
            ) : (
              <></>
            )
          ) : (
            <Outlet />
          )}
        </Box>
      </Box>
    </>
  );
});

function ProtectedApp() {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.startsWith("/report")) {
      dispatch(setSection("reports"));
    } else {
      dispatch(setSection("conversation"));
    }
  }, [location.pathname, dispatch]);

  if (!isAuthenticated) return <SignInPage />;
  if (isLoading) return <PageLoader variant="full" />;

  return (
    <CurrentUserProvider>
      <MainComponent />
    </CurrentUserProvider>
  );
}

export default ProtectedApp;
