import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../store";
import { ReportOnTemplate } from "../../models";
import { api } from "../../../../api/twelve";

interface ReportListState {
  items: Array<ReportOnTemplate>;
  loading: boolean;
  canLoadingMore: boolean;
  error: string | null;
}

const initialState: ReportListState = {
  items: [],
  loading: false,
  canLoadingMore: true,
  error: null,
};

const reportListSlice = createSlice({
  name: "reportList",
  initialState,
  reducers: {
    fetchItemsStart: (state) => {
      state.loading = true;
      state.canLoadingMore = true;
    },
    fetchItemsSuccess: (
      state,
      action: PayloadAction<{
        items: Array<ReportOnTemplate>;
        append?: boolean;
      }>,
    ) => {
      if (action.payload.append) {
        state.items = [...state.items, ...action.payload.items];
      } else {
        state.items = action.payload.items;
      }
      state.canLoadingMore = action.payload.items.length > 0;
      state.loading = false;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addItem: (state, action: PayloadAction<ReportOnTemplate>) => {
      state.items.unshift(action.payload);
    },
    updateItem: (state, action: PayloadAction<ReportOnTemplate>) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.items = [
          ...state.items.slice(0, index),
          action.payload,
          ...state.items.slice(index + 1),
        ];
      }
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
  },
});

export const {
  fetchItemsStart,
  fetchItemsSuccess,
  addItem,
  updateItem,
  deleteItem,
} = reportListSlice.actions;
export default reportListSlice.reducer;

// Thunk actions for API calls
export const fetchReports: any =
  (
    token: string,
    all: boolean,
    templateId: number,
    limit: number = 30,
    searchQuery: string | undefined = undefined,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(reportListSlice.actions.fetchItemsStart());
      const response: Array<ReportOnTemplate> =
        await api.getReportOnTemplateList(
          token,
          all,
          limit,
          templateId,
          searchQuery,
        );
      dispatch(
        reportListSlice.actions.fetchItemsSuccess({
          items: response,
          append: false,
        }),
      );
    } catch (error: any) {
      console.error("Failed to fetch reports:", error);
      dispatch(reportListSlice.actions.failure(error.message));
    }
  };
