import {
  Box,
  BoxProps,
  useTheme,
  styled,
  Pagination,
  IconButton,
  useMediaQuery,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandableText from "./ExpandableText";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoadingText from "./LoadingText";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { Swiper as SwiperType } from "swiper/types";

interface SwipeableContentProps {
  descriptions?: Array<string | undefined>;
  children: React.ReactNode;
  indicatorsVariant?: "circle" | "button";
  indicatorsStyle?: {
    activeColor?: string;
    disabledColor?: string;
  };
  textColor?: string;
  allowExpand?: boolean;
  minHeight?: string;
  useLoadingText?: boolean;
  onCurrentIndexChange?: (index: number) => void;
}

const IndicatorsBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "6px",
  height: "24px",
}));

const SwiperInstance = ({
  onInstance,
}: {
  onInstance: (instance: SwiperType) => void;
}) => {
  const swiper = useSwiper();
  useEffect(() => {
    onInstance(swiper);
  }, [onInstance, swiper]);

  return <></>;
};

const SwipeableContent: React.FC<SwipeableContentProps> = ({
  children,
  descriptions,
  indicatorsVariant = "circle",
  indicatorsStyle,
  allowExpand = true,
  textColor,
  useLoadingText = false,
  onCurrentIndexChange,
}) => {
  const [userSelectValue] = useState<"none" | "unset">("unset");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [activeIndex, setActiveIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        setActiveIndex(swiper.activeIndex);
        onCurrentIndexChange?.(swiper.activeIndex);
      });
    }
  }, [swiper, onCurrentIndexChange]);

  const handleJump = (index: number) => {
    if (swiper) {
      swiper.slideTo(index);
    }
  };

  const childrenCount = React.Children.count(children);

  const activeColor =
    indicatorsStyle?.activeColor || theme.palette.action.active;
  const disabledColor =
    indicatorsStyle?.disabledColor || theme.palette.action.disabled;

  return (
    <Box
      sx={{
        width: "100%",
        userSelect: userSelectValue,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Swiper
          spaceBetween={24}
          pagination={false}
          style={{ height: "100%", width: "100%" }}
        >
          <SwiperInstance onInstance={(instance) => setSwiper(instance)} />
          {React.Children.map(children, (child, index) => (
            <SwiperSlide key={index} style={{ height: "100%", width: "100%" }}>
              <Box
                sx={{
                  height: "100%",
                }}
              >
                {child}
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {childrenCount > 1 &&
        (indicatorsVariant === "circle" ? (
          <IndicatorsBox sx={{ height: "34px" }}>
            {!isMobile ? (
              <IconButton
                size="small"
                disabled={activeIndex === 0}
                onClick={() => handleJump(activeIndex - 1)}
                sx={{ color: activeColor }}
              >
                <KeyboardArrowLeftIcon sx={{ height: "18px", width: "18px" }} />
              </IconButton>
            ) : (
              <></>
            )}
            {React.Children.map(children, (_, index) => (
              <Box
                key={`indicator-${index}`}
                onClick={() => handleJump(index)}
                sx={{
                  cursor: "pointer",
                  width: activeIndex === index ? "16px" : "4px",
                  height: "4px",
                  borderRadius: "4px",
                  background:
                    activeIndex === index ? activeColor : disabledColor,
                }}
              />
            ))}
            {!isMobile ? (
              <IconButton
                size="small"
                disabled={activeIndex === childrenCount - 1}
                onClick={() => handleJump(activeIndex + 1)}
                sx={{ color: activeColor }}
              >
                <KeyboardArrowRightIcon
                  sx={{ height: "18px", width: "18px" }}
                />
              </IconButton>
            ) : (
              <></>
            )}
          </IndicatorsBox>
        ) : (
          <IndicatorsBox sx={{ height: "80px" }}>
            <Pagination
              count={childrenCount}
              page={activeIndex + 1}
              onChange={(event, value) => handleJump(value - 1)}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: alpha(
                    theme.palette.customColors.text,
                    theme.palette.customColors.opacity.text.primary,
                  ),
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  color: alpha(
                    theme.palette.customColors.text,
                    theme.palette.customColors.opacity.text.secondary,
                  ),
                  background: theme.palette.backgrounds.tertiary,
                },
              }}
            />
          </IndicatorsBox>
        ))}
      {descriptions?.length ? (
        <Box
          sx={{
            fontSize: "16px",
            marginTop: "16px",
            width: "100%",
          }}
        >
          {descriptions[activeIndex] ? (
            <ExpandableText
              text={descriptions[activeIndex]}
              allowExpand={allowExpand}
              color={textColor}
              useLoadingText={useLoadingText}
            />
          ) : (
            <LoadingText />
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SwipeableContent;
