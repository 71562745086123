import React, { useState } from "react";

import {
  Backdrop,
  Box,
  Collapse,
  Icon,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { BaseContext } from "../../../../models";

export interface AnalystChatFunctionSelection {
  chat_function_name: string;
  context: BaseContext;
}

export interface AnalystChatFunctionSelectProps {
  onChatFunctionSelected: (selection: AnalystChatFunctionSelection) => void;
}

const BaseAnalystChatFunctionSelect: React.FC<{
  title: string;
  children: React.ReactElement<AnalystChatFunctionSelectProps>;
}> = ({ title, children }) => {
  const headerHeight = "72px";
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Backdrop
        open={open}
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
        }}
      />
      <Box
        sx={{
          flexShrink: 0,
          height: `${headerHeight}`,
          position: "relative",
          width: "100%",
          background: theme.palette.backgrounds.primary,
        }}
      >
        <Box
          sx={{
            width: "100%",
            bottom: 0,
            position: "absolute",
            zIndex: 100,
          }}
        >
          <Collapse
            in={open}
            collapsedSize={headerHeight}
            sx={{
              borderRadius: "16px 16px 0 0",
              background: theme.palette.backgrounds.primary,
              boxShadow:
                "0px -1px 3px 0px rgba(0, 0, 0, 0.12), 0px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px -2px 1px -1px rgba(0, 0, 0, 0.20);",
            }}
          >
            <Stack>
              <Stack
                height={headerHeight}
                borderRadius="16px 16px 0 0"
                direction={"row"}
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? theme.palette.customColors.visualisation.background2
                      : theme.palette.backgrounds.secondary,
                  padding: "16px 24px",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "space-between",
                  color: theme.palette.text.primary,
                }}
                onClick={() => setOpen(!open)}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  {title}
                </Typography>
                <Icon>
                  <ArrowDropUpIcon
                    sx={{
                      transform: open ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </Icon>
              </Stack>
              <Stack
                className="scrollable"
                p={"16px"}
                sx={{
                  maxHeight: `calc(var(--vh, 1vh) * 100 - 240px)`,
                  overflow: "auto",
                }}
              >
                {children}
              </Stack>
            </Stack>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default BaseAnalystChatFunctionSelect;
