import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReportOnTemplate, ReportTemplate } from "../models";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import {
  addItem,
  updateItem,
  deleteItem,
} from "../store/slices/reportListSlice";
import { api } from "../../../api/twelve";
import { useCurrentUser } from "../../auth/contexts/CurrentUserContext";
import { UserPermissions } from "../../auth/models";
import ReportForm from "./components/ReportForm";

export default function ReportFormPage({
  variant = "create",
}: {
  variant: "create" | "update";
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentUser } = useCurrentUser();
  const { getAccessTokenSilently } = useAuth0();

  const [report, setReport] = useState<ReportOnTemplate>({
    id: 0,
    user_id: currentUser?.id,
    name: "",
    description: "",
    template_id: 0,
    content: {
      properties: [],
      sections: [],
    },
  });

  useEffect(() => {
    if (!location.state?.report) return;

    if (variant === "create") {
      const template = location.state.report as ReportTemplate;
      setReport({
        id: 0,
        user_id: currentUser?.id,
        name: "",
        description: template.description,
        template_id: template.id,
        content: template.structure,
      });
    } else {
      const fetchReport = async () => {
        const fetchedReport = await api.getReportOnTemplateById(
          await getAccessTokenSilently(),
          location.state.report.id,
          currentUser?.chekPermissions(
            UserPermissions.READ_ALL_SCOUT_REPORTS,
          ) && currentUser.id !== location.state?.report.user_id,
        );

        setReport(fetchedReport);
      };
      fetchReport();
    }
  }, [variant, location, currentUser, getAccessTokenSilently]);

  return (
    <ReportForm
      reportOnTemplate={report}
      variant={variant}
      onCreated={(newReport) => {
        dispatch(addItem(newReport));
        navigate("/report");
      }}
      onUpdated={(updatedReport) => {
        dispatch(updateItem(updatedReport));
        navigate("/report");
      }}
      onDeleted={(deletedReportId) => {
        dispatch(deleteItem(deletedReportId));
        navigate("/report");
      }}
      onDuplicated={(duplicatedReport) => {
        dispatch(addItem(duplicatedReport));
        navigate("/report");
      }}
      onCanceled={() => navigate("/report")}
    />
  );
}
