import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReportOnTemplate, ReportTemplatePropertyType } from "../../models";
import TemplatePropertyIcon from "./TemplatePropertyIcon";
import { useSnackbar } from "../../../../shared/contexts/SnackbarContext";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../../../api/twelve";
import SearchPlayerModal from "../../shared/search/SearchPlayerModal";
import { useCurrentUser } from "../../../auth/contexts/CurrentUserContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ResponsiveDropdownMenu, {
  ResponsiveDropdownMenuItemProps,
} from "../../../../shared/components/ResponsiveDropdownMenu";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import { UserPermissions } from "../../../auth/models";
import SearchTeamModal from "../../shared/search/SearchTeamModal";
import SearchMatchModal from "../../shared/search/SearchMatchModal";
import AddIcon from "@mui/icons-material/Add";
import StringHelper from "../../../../shared/helpers/string.helper";
import MentionsTextField from "../../shared/MentionsTextField/MentionsTextField";
import PageLoader from "../../../../shared/components/PageLoader";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ReportForm({
  variant = "create",
  reportOnTemplate,
  reportNamePlaceholder = "Report name",
  onCanceled,
  onCreated,
  onUpdated,
  onDeleted,
  onDuplicated,
}: {
  reportOnTemplate: ReportOnTemplate;
  reportNamePlaceholder?: string;
  variant: "create" | "update";
  onCanceled: () => void;
  onCreated: (report: ReportOnTemplate) => void;
  onUpdated: (report: ReportOnTemplate) => void;
  onDeleted: (reportId: number) => void;
  onDuplicated: (report: ReportOnTemplate) => void;
}) {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = useState<
    Array<ResponsiveDropdownMenuItemProps>
  >([]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [isErrorPropertiesDialogOpen, setIsErrorPropertiesDialogOpen] =
    useState(false);

  const handleOpenDeleteDialog = () => setDeleteDialogOpen(true);
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const [report, setReport] = useState<ReportOnTemplate>({
    id: 0,
    template_id: 0,
    name: "",
    description: "",
    content: { sections: [], properties: [] },
  });

  const [errors, setErrors] = useState({
    name: false,
    properties: false,
    sections: [
      {
        questions: [false],
      },
    ],
  });

  useEffect(() => {
    const setReportAndErrors = (report: ReportOnTemplate): void => {
      setErrors({
        name: false,
        properties: false,
        sections: report.content.sections.map((section) => ({
          questions: section.questions.map(() => false),
        })),
      });
      setReport(structuredClone(report));
    };

    if (variant === "create") {
      setCanUpdate(true);
      setMenuItems([]);
      setReportAndErrors(reportOnTemplate);
    } else {
      const updateMatchPropertyWithResultOfMatch = async (
        report: ReportOnTemplate,
      ): Promise<void> => {
        let needUpdate = false;
        const matchProperty = report.content.properties.find(
          (p) => p.type === "match",
        );
        if (matchProperty && matchProperty.data && matchProperty.data.length) {
          try {
            await Promise.all(
              matchProperty.data.map(async (match) => {
                if (!match.id) return;
                const matchDetails = await api.getMatchById(
                  await getAccessTokenSilently(),
                  match.id,
                );
                if (match.value !== matchDetails.label) {
                  match.value = matchDetails.label;
                  needUpdate = true;
                }
              }),
            );
          } catch {}
        }

        if (needUpdate) {
          const updatedReport = await api.updateReportOnTemplate(
            await getAccessTokenSilently(),
            reportOnTemplate,
          );
          setReportAndErrors(updatedReport);
        } else {
          setReportAndErrors(reportOnTemplate);
        }
      };

      updateMatchPropertyWithResultOfMatch(reportOnTemplate);

      if (currentUser) {
        const items = [
          {
            label: "Duplicate",
            onClick: async () => {
              try {
                const _duplicate = { ...reportOnTemplate };
                _duplicate.name = reportOnTemplate.name
                  ? `${reportOnTemplate.name} (copy)`
                  : "";
                _duplicate.template_name = reportOnTemplate.template_name;
                const duplicate = await api.addReportOnTemplate(
                  await getAccessTokenSilently(),
                  reportOnTemplate,
                );
                duplicate.template_name = reportOnTemplate.template_name;
                onDuplicated(duplicate);
                showSnackbar(`Report was duplicated`);
              } catch (error: any) {
                showSnackbar(`${error.message}`, {}, "error");
              }
            },
          },
        ];
        if (
          currentUser.id === reportOnTemplate.user_id ||
          currentUser.chekPermissions(UserPermissions.READ_ALL_SCOUT_REPORTS)
        ) {
          items.push({ divider: true } as any);
          items.push({
            label: "Delete",
            onClick: () => {
              handleOpenDeleteDialog();
            },
            color: "warning.main",
          } as any);
          setCanUpdate(true);
          setMenuItems(items);
        } else {
          setCanUpdate(false);
          setMenuItems(items);
        }
      }
    }
  }, [
    variant,
    reportOnTemplate,
    currentUser,
    getAccessTokenSilently,
    onDuplicated,
    showSnackbar,
  ]);

  const [open, setOpen] = useState<ReportTemplatePropertyType | undefined>(
    undefined,
  );

  const handleOpen = (type: ReportTemplatePropertyType | undefined) => {
    setOpen(type);
  };

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleSelectProperty = (
    propertyType: ReportTemplatePropertyType,
    data: Array<{ id: number; value: string }>,
  ) => {
    const newReport = {
      ...report,
      content: {
        ...report.content,
        properties: report.content.properties.map((property) =>
          property.type === propertyType
            ? {
                ...property,
                data: [...(property.data || []), ...data],
              }
            : property,
        ),
      },
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      properties: false,
    }));
    setReport(newReport);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setReport((prevReport) => ({
      ...prevReport,
      name: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      name: report.content.properties?.length === 0 && value.trim() === "",
    }));
  };

  const handleAnswerChange = (
    sectionIndex: number,
    questionIndex: number,
    answerIndex: number,
    value: string,
    localProperties: Array<{ id: number; value: string }> = [],
  ) => {
    setReport((prevReport) => {
      const newSections = prevReport.content.sections.map((section, sIndex) => {
        if (sIndex === sectionIndex) {
          return {
            ...section,
            questions: section.questions.map((question, qIndex) => {
              if (qIndex === questionIndex) {
                return {
                  ...question,
                  answer: Array.isArray(question.answer)
                    ? question.answer.map((a, aIndex) =>
                        aIndex === answerIndex ? value : a,
                      )
                    : [value],
                };
              }
              return question;
            }),
          };
        }
        return section;
      });

      if (localProperties.length) {
        const playerProperties = prevReport.content.properties.find(
          (p) => p.type === "player",
        );
        if (playerProperties) {
          if (!playerProperties.data) {
            playerProperties.data = [];
          }
          const ids = new Set(playerProperties.data.map((p) => p.id));
          playerProperties.data = [
            ...playerProperties.data,
            ...localProperties.filter((p) => !ids.has(p.id)),
          ];
        }
      }

      return {
        ...prevReport,
        content: {
          ...prevReport.content,
          sections: newSections,
        },
      };
    });
    setErrors((prevErrors) => {
      const newSectionErrors = [...prevErrors.sections];
      newSectionErrors[sectionIndex].questions[questionIndex] =
        value.trim() === "";
      return {
        ...prevErrors,
        sections: newSectionErrors,
      };
    });
  };

  const handleDuplicateQuestion = (
    sectionIndex: number,
    questionIndex: number,
  ) => {
    setReport((prevReport) => {
      const newSections = prevReport.content.sections.map((section, sIndex) => {
        if (sIndex === sectionIndex) {
          return {
            ...section,
            questions: section.questions.map((question, qIndex) => {
              if (qIndex === questionIndex) {
                return {
                  ...question,
                  answer: Array.isArray(question.answer)
                    ? [...question.answer, ""]
                    : [question.answer || "", ""],
                };
              }
              return question;
            }),
          };
        }
        return section;
      });

      return {
        ...prevReport,
        content: {
          ...prevReport.content,
          sections: newSections,
        },
      };
    });
  };

  const handleDeleteAnser = (
    sectionIndex: number,
    questionIndex: number,
    answerIndex: number,
  ) => {
    setReport((prevReport) => {
      const newSections = prevReport.content.sections.map((section, sIndex) => {
        if (sIndex === sectionIndex) {
          return {
            ...section,
            questions: section.questions.map((question, qIndex) => {
              if (qIndex === questionIndex) {
                return {
                  ...question,
                  answer: Array.isArray(question.answer)
                    ? question.answer.filter(
                        (_, aIndex) => aIndex !== answerIndex,
                      )
                    : [],
                };
              }
              return question;
            }),
          };
        }
        return section;
      });

      return {
        ...prevReport,
        content: {
          ...prevReport.content,
          sections: newSections,
        },
      };
    });
  };

  const handleSubmit = async (validate: boolean = true) => {
    let hasNoErrors = true;
    if (validate) {
      const nameError =
        report.content.properties?.length === 0 && report.name.trim() === "";
      const sectionErrors = report.content.sections.map((section) => ({
        questions: section.questions.map(
          (subquestion) =>
            // !subquestion.answer || subquestion.answer?.trim() === "",
            false,
        ),
      }));
      const propertiesError = report.content.properties.some(
        (p) => !p.data?.length,
      );

      if (propertiesError) {
        setIsErrorPropertiesDialogOpen(true);
        return;
      }

      setErrors({
        name: nameError,
        properties: false,
        sections: sectionErrors,
      });

      hasNoErrors =
        !nameError &&
        //!propertiesError &&
        !sectionErrors.some((section) =>
          section.questions.some((error) => error),
        );
    }

    if (hasNoErrors) {
      try {
        if (variant === "create") {
          onCreated(
            await api.addReportOnTemplate(
              await getAccessTokenSilently(),
              report,
            ),
          );
        } else {
          onUpdated(
            await api.updateReportOnTemplate(
              await getAccessTokenSilently(),
              report,
            ),
          );
        }
      } catch (error: any) {
        showSnackbar(`${error.message}`, {}, "error");
        return;
      }
      showSnackbar(`Report ${report.name} was saved`);
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <Box
      className="scrollable"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        padding: "16px",
        overflow: "auto",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
          maxWidth: "640px",
        }}
      >
        {variant === "update" && !report?.id ? (
          <PageLoader variant="full" />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "16px",
                width: "100%",
                [theme.breakpoints.down("md")]: {
                  pb: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                }}
              >
                <Button
                  variant="text"
                  size="medium"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={!canUpdate}
                >
                  Save
                </Button>
                <Button
                  variant="text"
                  size="medium"
                  color="secondary"
                  onClick={() => onCanceled()}
                >
                  Cancel
                </Button>
              </Box>
              {variant === "update" && (
                <>
                  <IconButton onClick={handleMenuButtonClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <ResponsiveDropdownMenu
                    anchorEl={menuAnchorEl}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    title={report.name}
                    menuItems={menuItems}
                  />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                padding: " 0 16px 16px 16px",
              }}
            >
              <Box>
                <TextField
                  variant="standard"
                  fullWidth
                  required
                  placeholder={reportNamePlaceholder}
                  value={report.name ?? ""}
                  onChange={handleNameChange}
                  error={errors.name}
                  helperText={errors.name ? "This field is required" : ""}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      fontSize: "24px",
                      fontWeight: 700,
                      "& .MuiInputBase-root": {
                        borderBottom: "none",
                      },
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "relative",
                  marginTop: "-12px",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    width: descriptionExpanded ? 0 : "60px",
                    background: `linear-gradient(90deg, rgba(245, 245, 245, 0.00) 0%, ${theme.palette.background.default} 87.5%)`,
                  },
                }}
              >
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    fontSize: "12px",
                    textOverflow: "ellipsis",
                    overflow: descriptionExpanded ? "unset" : "hidden",
                    whiteSpace: descriptionExpanded ? "unset" : "nowrap",
                    cursor: "pointer",
                  }}
                  onClick={() => setDescriptionExpanded(!descriptionExpanded)}
                >
                  {report.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {report.content.properties.map((property, index) => (
                    <Box
                      key={`property-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "12px",
                        color: (theme) => theme.palette.text.secondary,
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        <Box>
                          <Box
                            className="icon"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: "2px 4px 0 4px",
                              boxSizing: "border-box",
                            }}
                          >
                            <TemplatePropertyIcon property={property} />
                          </Box>
                        </Box>
                        <Button
                          variant="text"
                          color="secondary"
                          size="small"
                          disabled={property.type === "tags" || !canUpdate}
                          onClick={() => handleOpen(property.type)}
                          endIcon={<AddIcon />}
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {`Add ${property.type}`}
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          flexWrap: "wrap",
                          alignItems: "flex-start",
                          [theme.breakpoints.down("sm")]: {
                            flexDirection: "column",
                            gap: "8px",
                          },
                        }}
                      >
                        {property.data?.map((data, dataIndex) => (
                          <Chip
                            key={`chip-${dataIndex}`}
                            label={data.value}
                            disabled={!canUpdate}
                            variant="outlined"
                            onDelete={() => {
                              const newReport = {
                                ...report,
                                content: {
                                  ...report.content,
                                  properties: report.content.properties.map(
                                    (p) =>
                                      p.type === property.type
                                        ? {
                                            ...p,
                                            data: p.data?.filter(
                                              (d) => d.value !== data.value,
                                            ),
                                          }
                                        : p,
                                  ),
                                },
                              };
                              setReport(newReport);
                            }}
                            sx={{
                              "&.Mui-disabled": {
                                opacity: 1,
                                "& .MuiChip-deleteIcon": {
                                  display: "none",
                                },
                              },
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
                <SearchPlayerModal
                  open={open === "player"}
                  onClose={handleClose}
                  onSelect={(player) =>
                    handleSelectProperty("player", [
                      {
                        id: player.id,
                        value: player.name,
                      },
                    ])
                  }
                />
                <SearchTeamModal
                  open={open === "team"}
                  onClose={handleClose}
                  onSelect={(team) =>
                    handleSelectProperty("team", [
                      {
                        id: team.team_id,
                        value: team.team,
                      },
                    ])
                  }
                />
                <SearchMatchModal
                  open={open === "match"}
                  onClose={handleClose}
                  onSelect={(match) => {
                    handleSelectProperty("match", [
                      {
                        id: match.match_id,
                        value: match.label,
                      },
                    ]);
                  }}
                />
                {errors.properties && (
                  <Typography color="error" sx={{ fontSize: "0.75rem" }}>
                    Please select value for all properties
                  </Typography>
                )}
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  {report.content.sections.map((section, sectionIndex) => (
                    <React.Fragment key={`section-${sectionIndex}`}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                          color: "text.secondary",
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "16px",
                          padding: "16px",
                          zIndex: 0,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "text.secondary",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          {section.header}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          {section.questions.map((question, questionIndex) => (
                            <React.Fragment key={`question-${questionIndex}`}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  gap: "8px",
                                  width: "100%",
                                }}
                              >
                                {(Array.isArray(question.answer)
                                  ? question.answer
                                  : [question.answer]
                                ).map((answer, answerIndex) => (
                                  <MentionsTextField
                                    key={`answer-${answerIndex}`}
                                    className="delete-icon"
                                    value={answer || ""}
                                    variant={"standard"}
                                    fullWidth
                                    multiline
                                    disabled={!canUpdate}
                                    placeholder="Answer..."
                                    InputLabelProps={{
                                      shrink: false,
                                    }}
                                    InputProps={
                                      answerIndex > 0
                                        ? {
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  size="small"
                                                  edge="end"
                                                  className="delete-icon"
                                                  sx={{
                                                    visibility: "hidden",
                                                    opacity: 0,
                                                    transition:
                                                      "opacity 0.3s ease, visibility 0.3s ease",
                                                  }}
                                                  onClick={() => {
                                                    handleDeleteAnser(
                                                      sectionIndex,
                                                      questionIndex,
                                                      answerIndex,
                                                    );
                                                  }}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }
                                        : {}
                                    }
                                    sx={{
                                      "& .Mui-disabled": {
                                        color: `${theme.palette.text.primary}`,
                                        WebkitTextFillColor: `${theme.palette.text.primary}!important`,
                                      },
                                    }}
                                    onChange={(
                                      newValue: string,
                                      newPlainText: string,
                                      mentions: any[],
                                    ) => {
                                      handleAnswerChange(
                                        sectionIndex,
                                        questionIndex,
                                        answerIndex,
                                        newValue,
                                        mentions.map((m) => ({
                                          id: Number(m.id),
                                          value: String(m.display)
                                            .replaceAll("@", "")
                                            .trim(),
                                        })),
                                      );
                                    }}
                                    helperText={question.question}
                                    taggedPlayers={
                                      report.content.properties.find(
                                        (p) => p.type === "player",
                                      )?.data
                                    }
                                  />
                                ))}
                                {question.allowDuplicate && canUpdate ? (
                                  <Button
                                    variant="text"
                                    color="inherit"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    disabled={!canUpdate}
                                    onClick={() =>
                                      handleDuplicateQuestion(
                                        sectionIndex,
                                        questionIndex,
                                      )
                                    }
                                  >
                                    Duplicate question
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              {questionIndex !==
                                section.questions.length - 1 && <Divider />}
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                      {sectionIndex !== report.content.sections.length - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Paper>
      <ResponsiveDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        title="Delete report"
        actions={
          <>
            <Button
              variant="text"
              size="medium"
              color="secondary"
              onClick={() => {
                handleCloseDeleteDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="warning"
              onClick={async () => {
                if (!report.id) return;
                try {
                  await api.deleteReportOnTemplate(
                    await getAccessTokenSilently(),
                    report.id,
                  );
                  onDeleted(report.id);
                  showSnackbar(
                    `Report ${report.name} was deleted`,
                    { icon: <></>, textColor: theme.palette.error.main },
                    "warning",
                  );
                } catch (error: any) {
                  showSnackbar(`${error.message}`, {}, "error");
                }
                handleCloseDeleteDialog();
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Typography variant="body1" color={"text.secondary"}>
          This action can’t be undone.
        </Typography>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isErrorPropertiesDialogOpen}
        onClose={() => setIsErrorPropertiesDialogOpen(false)}
        title="Unfilled properties"
        actions={
          <>
            <Button
              variant="text"
              size="medium"
              color="secondary"
              onClick={() => {
                setIsErrorPropertiesDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={async () => {
                await handleSubmit(false);
                setIsErrorPropertiesDialogOpen(false);
              }}
            >
              Save anyway
            </Button>
          </>
        }
      >
        <Typography variant="body1" color={"text.secondary"}>
          You haven’t filled in properties:
        </Typography>
        <ul style={{ paddingLeft: "20px" }}>
          {report.content.properties
            .filter((property) => !property.data?.length)
            .map((property, index) => (
              <li key={`properties-error-${index}`}>
                <Typography variant="body1" color={"text.secondary"}>
                  {StringHelper.normalizeKey(property.type)}
                </Typography>
              </li>
            ))}
        </ul>
      </ResponsiveDialog>
    </Box>
  );
}
