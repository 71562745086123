import React, { useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import store from "./store";
import { config } from "./config";
import { SnackbarProvider } from "./shared/contexts/SnackbarContext";
import AppThemeProvider from "./themes/AppThemeProvider";
import AppRouter from "./AppRouter";

function App() {
  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Provider store={store}>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: config.auth0.audience,
        }}
      >
        <AppThemeProvider>
          <SnackbarProvider>
            <AppRouter />
          </SnackbarProvider>
        </AppThemeProvider>
      </Auth0Provider>
    </Provider>
  );
}

export default App;
