import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ScoutPlayerAnalystPane from "./scout-player/ScoutPlayerAnalystPane";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { toggleAnalystPane } from "../../../store/viewReducer";
import BaseAnalystPane from "./common-components/BaseAnalystPane";
import BaseAnalystChatFunctionSelect, {
  AnalystChatFunctionSelection,
} from "./common-components/BaseAnalystChatFunctionSelect";
import ScoutPlayerAnalystChatFunctionSelect from "./scout-player/ScoutPlayerAnalystChatFunctionSelect";
import { useChatContext } from "../ChatContext";

const AnalystPane: React.FC<{
  onChatFunctionSelected: (selection: AnalystChatFunctionSelection) => void;
}> = ({ onChatFunctionSelected }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const opened = useSelector(
    (state: RootState) => state.view.analystPane.opened,
  );
  const { chatContext } = useChatContext();

  const [open, setOpen] = useState<boolean>(false);

  const renderContent = () => {
    if (!chatContext) {
      return <></>;
    }

    return (
      <Stack height={"100%"} overflow={"hidden"}>
        <BaseAnalystPane>
          <ScoutPlayerAnalystPane />
        </BaseAnalystPane>
        <BaseAnalystChatFunctionSelect title={"Player Scout"}>
          <ScoutPlayerAnalystChatFunctionSelect
            onChatFunctionSelected={(
              selection: AnalystChatFunctionSelection,
            ) => {
              onChatFunctionSelected(selection);
            }}
          />
        </BaseAnalystChatFunctionSelect>
      </Stack>
    );
  };

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          className="scrollable"
          PaperProps={{
            elevation: 1,
            sx: {
              height: "calc(var(--vh, 1vh) * 100 - 56px)",
              background: theme.palette.background.default,
              overflow: "hidden",
            },
          }}
          onClose={() => dispatch(toggleAnalystPane(false))}
          onOpen={() => {}}
          open={open}
        >
          <Box
            sx={{
              position: "relative",
              height: "18px",
              background: theme.palette.background.paper,
            }}
          >
            <Box
              sx={{
                width: 32,
                height: 4,
                backgroundColor: theme.palette.common.white,
                opacity: 0.1,
                borderRadius: 2,
                position: "absolute",
                top: 8,
                left: "calc(50% - 16px)",
              }}
            />
          </Box>
          {renderContent()}
        </SwipeableDrawer>
      ) : (
        <Box
          className="scrollable"
          sx={{
            height: "calc(var(--vh, 1vh) * 100 - 64px)",
            width: "360px",
            flexShrink: 0,
            position: "relative",
            zIndex: 1060,
            transform: "unset",
            marginRight: opened ? "0" : "-360px",
            right: 0,
            borderLeft: `1px solid ${theme.palette.divider}`,
            background:
              theme.palette.mode === "dark"
                ? theme.palette.customColors.visualisation.background1
                : "inherit",
            transition: theme.transitions.create(["all"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          {renderContent()}
        </Box>
      )}
    </>
  );
};

export default AnalystPane;
