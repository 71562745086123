import {
  PaletteMode,
  PaletteOptions,
  Theme,
  createTheme,
  alpha,
} from "@mui/material";
import React from "react";
import { CustomColors } from "./customColors";
import {
  darkModeApplicationPalette,
  lightModeApplicationPalette,
} from "./palettes";

export type ThemeMode = PaletteMode | "system";

export const PaletteModeContext = React.createContext({
  mode: "light" as ThemeMode,
  setMode: (mode: ThemeMode) => {},
});

declare module "@mui/material/styles" {
  interface Palette {
    disabled: Palette["primary"];
    backgrounds: {
      primary: string;
      secondary: string;
      tertiary: string;
      code: string;
      bagde1: string;
      menu: string;
    };
    colors: {
      accent?: string;
      bagde1: string;
    };
    customColors: CustomColors;
  }

  interface PaletteOptions {
    disabled?: PaletteOptions["primary"];
    backgrounds?: {
      primary: string;
      secondary: string;
      tertiary: string;
      code: string;
      bagde1: string;
      menu: string;
    };
    colors?: {
      accent?: string;
      bagde1: string;
    };
    customColors: CustomColors;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    disabled: true;
  }
}

const getPalette = (
  mode: ThemeMode = "light",
  prefersDarkMode: boolean,
  customer = undefined,
): PaletteOptions => {
  switch (mode) {
    case "system":
      if (prefersDarkMode) return darkModeApplicationPalette;
      return lightModeApplicationPalette;
    case "dark":
      return darkModeApplicationPalette;
    default:
      return lightModeApplicationPalette;
  }
};

export const createCustomTheme = (
  mode: ThemeMode = "light",
  prefersDarkMode: boolean,
  customer = undefined,
) => {
  const palette = getPalette(mode, prefersDarkMode, customer);
  return createTheme({
    palette: palette,
    typography: {
      fontFamily: "Gilroy",
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            position: "fixed",
            background: "#002C1C",
            zIndex: theme.zIndex.drawer + 1,
          }),
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            fontSize: "0.875rem",
            borderRadius: "40px",
            textTransform: "unset",
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: "40px!important",
          },
        },
      },
      MuiCircularProgress: {
        variants: [
          {
            props: {
              size: "small",
            },
            style: {
              width: "30px",
              height: "30px",
            },
          },
        ],
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            backgroundColor: palette.backgrounds?.primary,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            zIndex: 9999,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            zIndex: 9999,
          },
        },
      },
      MuiSwipeableDrawer: {
        defaultProps: {
          slotProps: {
            root: {
              style: {
                zIndex: 9998,
              },
            },
          },
          PaperProps: {
            elevation: 1,
            sx: {
              borderRadius: "16px 16px 0 0",
            },
          },
          BackdropProps: {
            sx: {
              backdropFilter: "blur(3px)",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          },
        },
      },
    },
  });
};

export const getGlobalStyles = (theme: Theme) => ({
  body: {
    backgroundColor: theme.palette.background.default,
  },
  // Scrollbar styles
  "@supports (scrollbar-color: auto)": {
    ".scrollable": {
      scrollbarColor: `${theme.palette.action.disabled} transparent`,
      scrollbarWidth: "thin",
    },
    ".scrollable:hover": {
      scrollbarColor: `${theme.palette.action.active} transparent`,
    },
  },
  "@supports selector(::-webkit-scrollbar)": {
    "::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: "8px",
      height: "8px",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "16px",
      backgroundColor: theme.palette.action.disabled,
    },
    "::-webkit-scrollbar-thumb:hover": {
      borderRadius: "16px",
      backgroundColor: theme.palette.action.active,
    },
  },

  // Loading styles
  "@keyframes shimmer-text-animation": {
    "0%": {
      backgroundPosition: "300% 0",
    },
    "100%": {
      backgroundPosition: "-300% 0",
    },
  },

  ".loading-text": {
    width: "50%",
    borderRadius: "16px",
    color: "transparent!important",
    height: "16px",
    animation: "shimmer-text-animation 5s infinite linear",
    background: `linear-gradient(139deg, ${alpha("#D9D9D9", 0.25)} 0%, ${alpha(
      "#D9D9D9",
      0.2,
    )} 20%, ${alpha("#D9D9D9", 0.2)} 40%, ${alpha("#D9D9D9", 0.25)} 100%)`,
    backgroundSize: "300% 100%",
  },

  "@keyframes shimmer-box-animation": {
    "0%": {
      backgroundPosition: "0% 0%",
    },
    "100%": {
      backgroundPosition: "200% 200%",
    },
  },

  ".loading-box": {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    background: `linear-gradient(
                  135deg,
                  transparent 25%,
                  rgba(255, 255, 255, 0.035) 45%,
                  rgba(255, 255, 255, 0.075) 50%,
                  rgba(255, 255, 255, 0.035) 55%,
                  transparent 75%
                ) no-repeat`,
    transform: "rotate(180deg)",
    animation: "shimmer-box-animation 2.5s linear infinite",
    backgroundSize: "200% 200%",
  },
});
