import {
  Box,
  BoxProps,
  CircularProgress,
  InputAdornment,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  ListItemTextProps,
  ListProps,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef } from "react";

export const SearchBoxContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  overflow: "hidden",
}));

export const SearchBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: "1",
  overflow: "auto",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "50vh",
    minHeight: "50vh",
  },
}));

export function SearchProgress() {
  return (
    <Box display="flex" justifyContent="center" mt={2}>
      <CircularProgress size={"small"} />
    </Box>
  );
}

export const SearchList = styled(List)<ListProps>(({ theme }) => ({
  overflow: "hidden",
  height: "100%",
}));

export const SearchListItemButton = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }),
);

export const SearchListItemTextMain = styled(ListItemText)<ListItemTextProps>(
  ({ theme }) => ({
    width: "100%",
  }),
);

export const SearchListItemTextSecondary = styled(
  ListItemText,
)<ListItemTextProps>(({ theme }) => ({
  width: "100%",
  fontSize: "10px",
  "& *": {
    color: theme.palette.text.disabled,
  },
}));

export function NoSearchResults({
  text = "No matches found",
}: {
  text?: string;
}) {
  return (
    <Typography variant="body2" color="text.disabled">
      {text}
    </Typography>
  );
}

export function SearchDefaultText({
  text = "Type to start searching",
}: {
  text?: string;
}) {
  return (
    <Typography
      variant="body2"
      sx={{
        color: "text.disabled",
        fontSize: "16px",
      }}
    >
      {text}
    </Typography>
  );
}

export function SearchInput(props: TextFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <Box>
      <TextField
        fullWidth
        inputRef={inputRef}
        variant="standard"
        placeholder="Search..."
        InputLabelProps={{
          shrink: false,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: {
            "&:before": {
              borderBottom: "1px dotted",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "1px dotted",
            },
            "&:after": {
              borderBottom: "1px dotted",
            },
          },
        }}
        {...props}
      />
    </Box>
  );
}
