import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../hooks/useDebounce";

interface SearchOption {
  id: string | number;
  name: string;
  data: any;
}

interface SearchDropdownProps {
  label?: string;
  noOptionsText?: string;
  value?: SearchOption;
  fetchData: (query: string) => Promise<SearchOption[]>;
  onSelect: (option: SearchOption) => void;
  renderOption?: (option: SearchOption) => React.ReactNode;
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  fetchData,
  onSelect,
  label = "Search...",
  noOptionsText = "No results found",
  value = null,
  renderOption = (option: SearchOption) => <>{option?.name}</>,
}) => {
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const [options, setOptions] = useState<SearchOption[]>(value ? [value] : []);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SearchOption | null>(
    value,
  );

  useEffect(() => {
    if (value) {
      setSelectedOption(value);
    }
  }, [value]);

  useEffect(() => {
    if (debouncedSearchText.trim() === "" || debouncedSearchText.length < 3) {
      setOptions([]);
      return;
    }
    const loadOptions = async () => {
      setLoading(true);
      try {
        const data = await fetchData(debouncedSearchText);
        setOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    loadOptions();
  }, [debouncedSearchText, fetchData]);

  return (
    <Autocomplete
      value={selectedOption}
      options={options}
      filterOptions={(options) => options}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option?.name || ""
      }
      noOptionsText={noOptionsText}
      onInputChange={(event, value) => {
        setSearchText(value);
      }}
      onChange={(event, value) => {
        setSelectedOption(value);
        onSelect(value as SearchOption);
        setOptions([value as SearchOption]);
      }}
      popupIcon={<SearchIcon />}
      sx={{
        "& .MuiAutocomplete-popupIndicatorOpen": {
          transform: "none",
        },
      }}
      ListboxProps={{ className: "scrollable" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            sx: {
              "&:before": {
                borderBottom: "1px dotted",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: "1px dotted",
              },
              "&:after": {
                borderBottom: "1px dotted",
              },
            },
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, state) => (
        <li {...props} key={option?.id}>
          {renderOption(option as any)}
        </li>
      )}
    />
  );
};

export default SearchDropdown;
