import { TextField, TextFieldProps, TextFieldVariants } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "./Highlighter";
import {
  BaseSuggestionData,
  DefaultDisplayTransform,
  DefaultMarkupTemplate,
  DefaultTrigger,
  MentionData,
  SuggestionData,
  SuggestionDataSource,
  SuggestionsQueryInfo,
} from "./types";
import {
  applyChangeToValue,
  findStartOfMentionInPlainText,
  getMentions,
  getPlainText,
  isNumber,
  makeMentionsMarkup,
  mapPlainTextIndex,
  spliceString,
} from "./utils/utils";
import SearchPlayerModal from "../search/SearchPlayerModal";

interface MentionsTextFieldBaseProps<T extends BaseSuggestionData> {
  /**
   * The current value of the TextField, potentially containing mention
   * markup.
   */
  value?: string;

  /** The default value. Use when the component is not controlled. */
  defaultValue?: string;

  /**
   * Callback invoked as the value of the TextField changes.
   * @param newValue The new markup value of the TextField.
   * @param newPlainText The new plain text value of the TextField, with
   *   mention markup converted to display strings.
   * @param mentions A list of mentions in the TextField.
   */
  onChange?: (
    newValue: string,
    newPlainText: string,
    mentions: MentionData[],
  ) => void;

  /**
   * A list of data sources used to populate the suggestions overlay.
   */
  dataSources?: SuggestionDataSource<T>[];

  /**
   * The color of the mention highlights.
   * @default 'primary.light'
   */
  highlightColor?: string;
  taggedPlayers?: any[];
}

export type MentionsTextFieldProps<
  T extends BaseSuggestionData,
  Variant extends TextFieldVariants = TextFieldVariants,
> = Omit<TextFieldProps<Variant>, "onChange" | "defaultValue"> &
  MentionsTextFieldBaseProps<T>;

export default function MentionsTextField<T extends BaseSuggestionData>(
  props: MentionsTextFieldProps<T>,
) {
  const [stateValue, setStateValue] = useState<string>(
    props.defaultValue || "",
  );

  const [inputRef, setInputRef] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);
  const highlighterRef = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<HTMLSpanElement>(null);
  //   const suggestionsMouseDown = useRef(false);

  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

  useEffect(() => {
    const input = inputRef;
    const onScroll = () => {
      if (!highlighterRef.current || !input) {
        return;
      }
      highlighterRef.current.scrollLeft = input.scrollLeft;
      highlighterRef.current.scrollTop = input.scrollTop;
    };

    input?.addEventListener("scroll", onScroll);
    return () => input?.removeEventListener("scroll", onScroll);
  }, [inputRef, highlighterRef]);

  useEffect(() => {
    const input = inputRef;
    if (
      !input ||
      (input.selectionStart === selectionStart &&
        input.selectionEnd === selectionEnd)
    ) {
      return;
    }
    input.setSelectionRange(selectionStart, selectionEnd);
  }, [selectionStart, selectionEnd, inputRef]);

  const {
    value,
    defaultValue: _defaultValue,
    dataSources = [{ data: [] }],
    highlightColor,
    taggedPlayers,
    ...others
  } = props;
  const finalValue = value !== undefined ? value : stateValue;

  const handleBlur = () => {
    // if (!suggestionsMouseDown.current) {
    //   setSelectionStart(0);
    //   setSelectionEnd(0);
    // }
    // suggestionsMouseDown.current = false;
  };

  const addMention = (
    suggestion: SuggestionData<T>,
    {
      querySequenceStart,
      querySequenceEnd,
      plainTextValue,
    }: SuggestionsQueryInfo,
  ) => {
    const start = mapPlainTextIndex(
      finalValue,
      dataSources,
      querySequenceStart,
      "START",
    );
    if (!isNumber(start)) {
      return;
    }

    const end = start + querySequenceEnd - querySequenceStart;

    let insert = makeMentionsMarkup(
      DefaultMarkupTemplate,
      suggestion.id,
      suggestion.display,
    );
    let displayValue = DefaultDisplayTransform(
      suggestion.id,
      suggestion.display,
    );

    const newCaretPosition = querySequenceStart + displayValue.length;
    setSelectionStart(newCaretPosition);
    setSelectionEnd(newCaretPosition);

    // Propagate change
    const newValue = spliceString(finalValue, start, end, insert);
    const mentions = getMentions(newValue, dataSources);
    const newPlainTextValue = spliceString(
      plainTextValue,
      querySequenceStart,
      querySequenceEnd,
      displayValue,
    );

    const onChange = props.onChange || setStateValue;
    onChange(newValue, newPlainTextValue, mentions);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let newPlainTextValue = ev.target.value;

    let selectionStartBefore = selectionStart;
    if (!isNumber(selectionStartBefore)) {
      selectionStartBefore = ev.target.selectionStart;
    }

    let selectionEndBefore = selectionEnd;
    if (!isNumber(selectionEndBefore)) {
      selectionEndBefore = ev.target.selectionEnd;
    }

    // Derive the new value to set by applying the local change in the textarea's plain text
    const newValue = applyChangeToValue(
      finalValue,
      newPlainTextValue,
      selectionStartBefore,
      selectionEndBefore,
      ev.target.selectionEnd || 0,
      dataSources,
      props.multiline,
    );

    // In case a mention is deleted, also adjust the new plain text value
    newPlainTextValue = getPlainText(newValue, dataSources);

    // Save current selection after change to be able to restore caret position after rerendering
    let selectionStartAfter = ev.target.selectionStart;
    let selectionEndAfter = ev.target.selectionEnd;

    // Adjust selection range in case a mention will be deleted by the characters outside of the
    // selection range that are automatically deleted
    const startOfMention = findStartOfMentionInPlainText(
      finalValue,
      dataSources,
      ev.target.selectionStart || 0,
    );
    if (
      startOfMention !== undefined &&
      selectionEndAfter !== null &&
      selectionEndAfter > startOfMention
    ) {
      // only if a deletion has taken place
      const data = (ev.nativeEvent as any).data;
      selectionStartAfter = startOfMention + (data ? data.length : 0);
      selectionEndAfter = selectionStartAfter;
    }

    setSelectionStart(selectionStartAfter);
    setSelectionEnd(selectionEndAfter);

    const mentions = getMentions(newValue, dataSources);

    // Propagate change
    const onChange = props.onChange || setStateValue;
    onChange(newValue, newPlainTextValue, mentions);
  };

  const handleSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSelectionStart(ev.target.selectionStart);
    setSelectionEnd(ev.target.selectionEnd);
    props.onSelect?.(ev);
  };

  const inputProps: TextFieldProps = {
    ...others,
    value: getPlainText(finalValue, dataSources, props.multiline),
    onChange: handleChange,
    onSelect: handleSelect,
    onBlur: handleBlur,
    inputProps: {
      sx: { overscrollBehavior: "none" },
    },
  };

  const [open, setOpen] = useState<boolean>(false);
  const [querySequenceStart, setQuerySequenceStart] = useState<number>(0);
  const [querySequenceEnd, setQuerySequenceEnd] = useState<number>(0);

  return (
    <>
      <Highlighter
        highlighterRef={highlighterRef}
        cursorRef={cursorRef}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
        value={finalValue}
        dataSources={dataSources}
        inputRef={inputRef}
        multiline={inputProps.multiline}
        color={highlightColor || props.color}
      />
      <TextField
        inputRef={(ref) => setInputRef(ref)}
        {...inputProps}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === DefaultTrigger) {
            event.stopPropagation();
            event.preventDefault();
            setQuerySequenceStart(selectionStart || 0);
            setQuerySequenceEnd(selectionEnd || 0);
            setOpen(true);
          }
        }}
        sx={{
          "&:hover .delete-icon": {
            visibility: "visible",
            opacity: 1,
          },
        }}
      />
      <SearchPlayerModal
        open={open}
        onClose={() => setOpen(false)}
        onSelect={(player) => {
          addMention(
            { id: player.id, display: player.name } as any,
            {
              querySequenceStart: querySequenceStart,
              querySequenceEnd: querySequenceEnd,
              plainTextValue: finalValue,
            } as SuggestionsQueryInfo,
          );
        }}
        taggedPlayers={taggedPlayers}
      />
    </>
  );
}
