import { BaseChartProps, HalfPitchChartWrapper } from "./ChartLayout";
import {
  ChartBody,
  ChartContainer,
  ChartFooter,
  ChartHeader,
} from "./ChartLayout";
import {
  useTheme,
  alpha,
  styled,
  useMediaQuery,
  Tooltip,
  Typography,
} from "@mui/material";
import { PitchChartZone } from "../../../api/twelve";

interface HalfPitchChartProps extends BaseChartProps {
  title: string;
  subTitle?: string;
  footerText?: string;
  data: any;
  size?: "lg" | "sm";
}

const StyledFooterText = styled("div")(({ theme }) => ({
  color: alpha(
    theme.palette.customColors.text,
    theme.palette.customColors.opacity.text.disabled,
  ),
  fontSize: "14px",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

export function HalfPitchChart(props: HalfPitchChartProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { size } = props;

  const x = (value: number) => value * 1.05;
  const y = (value: number) => value * 0.68;

  const bestZone: PitchChartZone | undefined = props.data?.reduce(
    (prev: PitchChartZone, current: PitchChartZone) =>
      prev?.value_z! > current?.value_z! ? prev : current,
    undefined,
  );

  const worstZone: PitchChartZone | undefined = props.data?.reduce(
    (prev: PitchChartZone, current: PitchChartZone) =>
      prev?.value_z! < current?.value_z! ? prev : current,
    undefined,
  );

  return (
    <>
      <ChartContainer {...props}>
        <ChartHeader {...props} />
        <ChartBody
          size={size}
          useTransparentBackground={props.useTransparentBackground}
          sx={{
            padding: "0 10px",
            flexGrow: 1,
            justifyContent: "space-around",
            maxHeight: `${size === "sm" || isMobile ? "380px" : "unset"}`,
          }}
        >
          <HalfPitchChartWrapper {...props}>
            <g>
              {props.data.map((item: PitchChartZone, index: number) => {
                const zx = x(item.bounding_box.x_min - 50);
                const zy = y(100 - item.bounding_box.y_max);
                const width = x(
                  item.bounding_box.x_max - item.bounding_box.x_min,
                );
                const height = y(
                  item.bounding_box.y_max - item.bounding_box.y_min,
                );
                const isBestOrWorst =
                  item.annotation === bestZone?.annotation ||
                  item.annotation === worstZone?.annotation;

                return (
                  <g key={`zone-${index}`}>
                    <defs>
                      <radialGradient
                        id={`zone-gradient-${index}`}
                        cx="50%"
                        cy="50%"
                        r="50%"
                        fx="50%"
                        fy="50%"
                      >
                        <stop
                          offset="0%"
                          stopColor={alpha(
                            theme.palette.customColors.teamColors.main,
                            0.5,
                          )}
                        />
                        <stop
                          offset="100%"
                          stopColor={theme.palette.customColors.teamColors.main}
                        />
                      </radialGradient>
                    </defs>
                    <rect
                      x={zx}
                      y={zy}
                      width={width}
                      height={height}
                      fill={`url(#zone-gradient-${index})`}
                      fillOpacity={item.color_value}
                    ></rect>
                    {
                      <Tooltip
                        placement={"bottom"}
                        enterTouchDelay={0}
                        title={
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "10px" }}
                            dangerouslySetInnerHTML={{
                              __html: item.hover_text?.replaceAll(
                                "\n",
                                "<br/>",
                              ),
                            }}
                          />
                        }
                      >
                        <text
                          x={zx + width / 2}
                          y={zy + height / 2}
                          fill={alpha(
                            theme.palette.customColors.text,
                            isBestOrWorst
                              ? theme.palette.customColors.opacity.text.primary
                              : theme.palette.customColors.opacity.text
                                  .disabled,
                          )}
                          alignmentBaseline="middle"
                          textAnchor="middle"
                          fontSize={"2px"}
                          fontWeight={isBestOrWorst ? 700 : 400}
                          transform={`rotate(90,${zx + width / 2},${
                            zy + height / 2
                          })`}
                        >
                          {item.annotation}
                        </text>
                      </Tooltip>
                    }
                  </g>
                );
              })}
            </g>
          </HalfPitchChartWrapper>
          {props.footerText ? (
            <ChartFooter
              size={size}
              sx={{
                background: "transparent!important",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <StyledFooterText sx={size === "sm" ? { fontSize: "12px" } : {}}>
                {props.footerText}
              </StyledFooterText>
            </ChartFooter>
          ) : (
            <></>
          )}
        </ChartBody>
      </ChartContainer>
    </>
  );
}
