import React from "react";
import {
  alpha,
  Box,
  BoxProps,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";

const InfoCard: React.FC<{
  title: string;
  items?: Array<{ key: string; value: string }>;
}> = ({ title, items }) => {
  const theme = useTheme();
  const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    borderRadius: "16px",
    padding: "24px",
    background:
      theme.palette.mode === "dark"
        ? `linear-gradient(
      139deg,
      ${theme.palette.customColors.visualisation.background1} 0%,
      ${alpha(
        theme.palette.customColors.visualisation.background1,
        0.95,
      )} 100%),
      ${theme.palette.customColors.section.gradient}
    `
        : theme.palette.background.paper,
  }));

  return (
    <StyledBox>
      <Typography
        variant="caption"
        sx={{
          color: theme.palette.text.primary,
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        {title}
      </Typography>
      <Grid container spacing={1}>
        {items?.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: "14px",
                }}
              >
                {item.key}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} textAlign={"right"}>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "14px",
                }}
              >
                {item.value || "-"}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </StyledBox>
  );
};

export default InfoCard;
