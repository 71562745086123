import { styled, SvgIcon, SvgIconProps, Tab, Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)({
  height: "48px",
});

export const StyledTab = styled(Tab)({
  height: "100%",
  minHeight: "48px",
  textTransform: "capitalize",
});

export const StyledTwelveIcon = styled(SvgIcon)<SvgIconProps>(({ theme }) => ({
  width: "20px",
  height: "20px",
  marginTop: "9px",
  marginRight: "2px!important",
  "& path": {
    fill: "inherit",
  },
}));
