import React from "react";
import { PlayerSummaryReportSlide, ReportPage } from "../../models";
import {
  Page,
  PageBox,
  PageBoxContent,
  PageBoxHeader,
  PageContent,
  PageHeader,
  PageHeaderProfile,
} from "../../shared/ReportLayout";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from "@mui/material";
import { PlayerPositionChart } from "../../../../shared/components/charts/PlayerPositionChart";
import PerformanceBar from "../../shared/PerformanceBar";
import StringHelper from "../../../../shared/helpers/string.helper";
import PlayerPositionHelper from "../../../../shared/helpers/playerPosition.helper";
import { CompetitionInfo, PlayerPositionInfo } from "../../../../models";

export default function PlayerSummaryPage({
  page,
  pageNumber,
  competition,
}: {
  page: ReportPage;
  pageNumber: number;
  competition?: CompetitionInfo;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const playerInfo = page.slides![0] as PlayerSummaryReportSlide;
  const normalizeKey = StringHelper.normalizeKey;
  const color1 = alpha(
    theme.palette.customColors.text,
    theme.palette.customColors.opacity.text.primary,
  );
  const color2 = alpha(
    theme.palette.customColors.text,
    theme.palette.customColors.opacity.text.secondary,
  );
  const getCompetitionYear = (
    competition: CompetitionInfo | undefined,
  ): string => {
    if (!competition) return "";
    if (competition.year === competition.end_year) {
      return String(competition.year).slice(-2);
    }
    return `${String(competition.year).slice(-2)}/${String(
      competition.end_year,
    ).slice(-2)}`;
  };

  playerInfo.position_card.positions =
    playerInfo.position_card.positions.reduce((acc, position) => {
      const existingPosition = acc.find(
        (p) =>
          PlayerPositionHelper.getPosition(p.name).name ===
          PlayerPositionHelper.getPosition(position.name).name,
      );
      if (existingPosition) {
        if (position.is_main) {
          existingPosition.is_main = true;
        }
        existingPosition.minutes += position.minutes;
      } else {
        acc.push(position);
      }
      return acc;
    }, [] as Array<PlayerPositionInfo>);

  const totalMinutes = playerInfo.position_card.positions.reduce(
    (acc, position) => acc + position.minutes,
    0,
  );
  playerInfo.position_card.positions = playerInfo.position_card.positions.map(
    (position) => ({
      ...position,
      percent: position.minutes / totalMinutes,
    }),
  );

  const positions = playerInfo.position_card.positions;
  const mainPositionIndex = positions.reduce(
    (maxIndex, pos, index, arr) =>
      pos.minutes > arr[maxIndex].minutes ? index : maxIndex,
    0,
  );
  const mainPosition = positions[mainPositionIndex];
  playerInfo.position_card.positions[mainPositionIndex].is_main = true;
  const other_positions = positions
    .filter((pos, index) => index !== mainPositionIndex)
    .map((pos) => PlayerPositionHelper.getPosition(pos.name).name)
    .filter(Boolean);

  return (
    <Page key={`page-${pageNumber}`} sx={{ height: "unset" }}>
      {!isMobile && (
        <PageHeader title={page.header} subtitle={page.subheader} />
      )}
      <PageContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            width: "100%",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <PageHeaderProfile
              title={playerInfo?.player_name || ""}
              src={playerInfo.player_image_url}
            />
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                height: "100%",
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                  maxWidth: "320px",
                  [theme.breakpoints.down("md")]: {
                    maxWidth: "100%",
                  },
                }}
              >
                <PageBox sx={{ flexGrow: 1 }}>
                  <PageBoxHeader title="Info" />
                  <PageBoxContent>
                    <Grid container spacing={1}>
                      {Object.entries(playerInfo?.player_info_card).map(
                        ([key, value]) => (
                          <React.Fragment key={`info-${key}`}>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body2" color={color2}>
                                {key === "transfermarkt_value"
                                  ? "Estimated value"
                                  : key === "best_foot"
                                  ? "Strong foot"
                                  : normalizeKey(key)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Typography
                                align={"right"}
                                variant="body2"
                                color={color1}
                              >
                                {value
                                  ? key === "transfermarkt_value"
                                    ? StringHelper.formatCurrency(Number(value))
                                    : key === "best_foot"
                                    ? normalizeKey(String(value))
                                    : String(value)?.replaceAll(",", ", ")
                                  : "-"}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ),
                      )}
                    </Grid>
                  </PageBoxContent>
                </PageBox>
                <PageBox sx={{ flexGrow: 1 }}>
                  <PageBoxHeader
                    title={`${
                      competition?.synthesized_name
                    } ${getCompetitionYear(competition)}`}
                  />
                  <PageBoxContent>
                    <Grid container spacing={1}>
                      {Object.entries(playerInfo?.player_season_info_card).map(
                        ([key, value]) => (
                          <React.Fragment key={`info-${key}`}>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body2" color={color2}>
                                {normalizeKey(key)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Typography
                                align={"right"}
                                variant="body2"
                                color={color1}
                              >
                                {String(value)}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ),
                      )}
                    </Grid>
                  </PageBoxContent>
                </PageBox>
              </Box>
              <PageBox sx={{ height: "100%", width: "100%" }}>
                <PageBoxHeader title={playerInfo?.player_summary_card.header} />
                <PageBoxContent>
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                    }}
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: playerInfo?.player_summary_card.description,
                    }}
                  />
                </PageBoxContent>
              </PageBox>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              minWidth: "20%",
              maxWidth: "30%",
              alignContent: "stretch",
              [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
              },
            }}
          >
            <PageBox sx={{ flexGrow: 1 }}>
              <PageBoxContent sx={{ overflow: "hidden" }}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Typography
                      component={"p"}
                      variant="body1"
                      sx={{ fontSize: "18px", fontWeight: 700 }}
                    >
                      Main position
                    </Typography>
                    <Typography component={"p"} variant="body2">
                      {
                        PlayerPositionHelper.getPosition(mainPosition?.name)
                          .name
                      }
                    </Typography>
                    <br />
                    {other_positions?.length ? (
                      <>
                        <Typography
                          component={"p"}
                          variant="body1"
                          sx={{
                            color: alpha(
                              theme.palette.customColors.text,
                              theme.palette.customColors.opacity.text.secondary,
                            ),
                            fontWeight: 700,
                          }}
                        >
                          Other positions
                        </Typography>
                        {other_positions.map((position, index) => (
                          <Typography
                            key={`other-pos-${index}`}
                            component={"p"}
                            variant="body2"
                            sx={{
                              color: alpha(
                                theme.palette.customColors.text,
                                theme.palette.customColors.opacity.text
                                  .secondary,
                              ),
                            }}
                          >
                            {position}
                          </Typography>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: 0,
                    }}
                  >
                    <PlayerPositionChart
                      width={"200px"}
                      positions={playerInfo.position_card.positions}
                      style={{ height: "100%" }}
                    />
                  </Grid>
                </Grid>
              </PageBoxContent>
            </PageBox>
            {playerInfo?.strengths_card.items?.length ? (
              <PageBox sx={{ flexGrow: 1 }}>
                <PageBoxHeader title="Strengths" />
                <PageBoxContent>
                  <Grid container spacing={2} alignItems={"center"}>
                    {playerInfo?.strengths_card.items
                      .sort((a, b) => a.rank - b.rank)
                      .map((qualityItem, index) => (
                        <React.Fragment key={`strenghts-${index}`}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Typography component={"p"} variant="body2">
                                {qualityItem.name}
                              </Typography>
                              <Typography
                                component={"p"}
                                variant="body2"
                                fontSize={12}
                              >
                                {qualityItem.rank}/{qualityItem.rank_out_of}
                              </Typography>
                            </Box>
                            <PerformanceBar
                              color1={color1}
                              color2={color2}
                              value={
                                100 -
                                (100 * (qualityItem.rank - 1)) /
                                  (qualityItem.rank_out_of - 1)
                              }
                              hoverText={`
                                ${playerInfo.player_name}<br/>
                                Ranked ${qualityItem.rank}/${
                                qualityItem.rank_out_of
                              }<br/>
                              Compared to other ${normalizeKey(
                                playerInfo.position_card.position,
                              )}s
                              `}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                  </Grid>
                </PageBoxContent>
              </PageBox>
            ) : (
              <></>
            )}
            {playerInfo?.weaknesses_card.items?.length ? (
              <PageBox sx={{ flexGrow: 1 }}>
                <PageBoxHeader title="Weaknesses" />
                <PageBoxContent>
                  <Grid container spacing={2} alignItems={"center"}>
                    {playerInfo?.weaknesses_card.items
                      .sort((a, b) => a.rank - b.rank)
                      .map((qualityItem, index) => (
                        <React.Fragment key={`strenghts-${index}`}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Typography component={"p"} variant="body2">
                                {qualityItem.name}
                              </Typography>
                              <Typography
                                component={"p"}
                                variant="body2"
                                fontSize={12}
                              >
                                {qualityItem.rank}/{qualityItem.rank_out_of}
                              </Typography>
                            </Box>
                            <PerformanceBar
                              color1={color1}
                              color2={color2}
                              value={
                                100 -
                                (100 * (qualityItem.rank - 1)) /
                                  (qualityItem.rank_out_of - 1)
                              }
                              hoverText={`
                                ${playerInfo.player_name}<br/>
                                Ranked ${qualityItem.rank}/${
                                qualityItem.rank_out_of
                              }<br/>
                                Compared to other ${normalizeKey(
                                  playerInfo.position_card.position,
                                )}s
                            `}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                  </Grid>
                </PageBoxContent>
              </PageBox>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </PageContent>
    </Page>
  );
}
