import { Box, useTheme } from "@mui/material";

export interface BaseAnalystPaneProps {}

const BaseAnalystPane: React.FC<{
  children: React.ReactElement<BaseAnalystPaneProps>;
}> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: "auto",
        height: "100%",
        background: theme.palette.backgrounds.primary,
        zIndex: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  );
};

export default BaseAnalystPane;
