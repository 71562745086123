import React, { useEffect, useRef, useState } from "react";
import { api } from "../../../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import useDebounce from "../../../../shared/hooks/useDebounce";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import {
  NoSearchResults,
  SearchBox,
  SearchBoxContainer,
  SearchDefaultText,
  SearchInput,
  SearchList,
  SearchListItemButton,
  SearchListItemTextMain,
  SearchListItemTextSecondary,
  SearchProgress,
} from "./SearchLayoutComponents";
import { SearchPlayerInfo } from "../../../../models";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";

interface SearchPlayerProps {
  open: boolean;
  onClose: () => void;
  onSelect: (player: SearchPlayerInfo) => void;
  taggedPlayers?: Array<{ id: number; value: string }>;
}

const SearchPlayerModal: React.FC<SearchPlayerProps> = ({
  open,
  onClose,
  onSelect,
  taggedPlayers,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [players, setPlayers] = useState<SearchPlayerInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const debouncedSearchTerm = useDebounce(searchQuery, 600);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setSearchQuery("");
    setPlayers([]);
  }, [open]);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const search = async () => {
      setLoading(true);
      try {
        const result = await api.searchPlayers(
          await getAccessTokenSilently(),
          debouncedSearchTerm,
          controller.signal,
        );
        setPlayers(result);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    if (debouncedSearchTerm?.length < 3) {
      if (!debouncedSearchTerm.length) {
        setPlayers([]);
      }
      return;
    }

    search();

    return () => {
      controller.abort();
    };
  }, [getAccessTokenSilently, debouncedSearchTerm]);

  const [expanded, setExpanded] = useState(false);

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      position="top"
    >
      <SearchBoxContainer>
        <SearchInput
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <SearchBox sx={{ overflow: `${loading ? "hidden" : "auto"}` }}>
          {taggedPlayers?.length ? (
            <Box sx={{ marginBottom: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontSize: " 14px", textTransform: "uppercase" }}
              >
                Tagged Players
              </Typography>
              <Collapse
                in={expanded}
                collapsedSize={`${Math.max(
                  48,
                  40 * Math.min(3, taggedPlayers.length),
                )}px`}
                timeout={400}
              >
                <SearchList dense>
                  {taggedPlayers.map((player, index) => (
                    <SearchListItemButton
                      key={`tagged-player-${index}`}
                      onClick={() => {
                        onSelect({
                          id: player.id,
                          name: player.value,
                          team: "",
                        });
                        onClose();
                      }}
                    >
                      <SearchListItemTextMain primary={player.value} />
                    </SearchListItemButton>
                  ))}
                </SearchList>
              </Collapse>
              {taggedPlayers.length > 3 ? (
                <Box
                  sx={{
                    boxSizing: "border-box",
                    padding: "4px 0 12px 0",
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => setExpanded(!expanded)}
                    sx={{ color: "primary.main" }}
                  >
                    {expanded ? "Show less" : "Show more"}
                  </Button>
                </Box>
              ) : (
                <></>
              )}
              <Divider />
            </Box>
          ) : (
            <></>
          )}
          {loading ? (
            <SearchProgress />
          ) : debouncedSearchTerm.length ? (
            players.length > 0 ? (
              <SearchList dense className="scrollable">
                {players.map((player, index) => (
                  <SearchListItemButton
                    key={`player-${index}`}
                    onClick={() => {
                      onSelect(player);
                      onClose();
                    }}
                  >
                    <SearchListItemTextSecondary secondary={player.team} />
                    <SearchListItemTextMain primary={player.name} />
                  </SearchListItemButton>
                ))}
              </SearchList>
            ) : (
              <NoSearchResults text="No players found" />
            )
          ) : (
            <SearchDefaultText text="Find players by their first name and/or last name." />
          )}
        </SearchBox>
      </SearchBoxContainer>
    </ResponsiveDialog>
  );
};

export default SearchPlayerModal;
