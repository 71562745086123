import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppSectionType } from "../models";

interface ViewState {
  sidebar: {
    opened: boolean;
  };
  section: AppSectionType;
  analystPane: {
    opened: boolean;
    mobileHeader?: {
      logo?: string;
      title?: string;
    };
  };
}

const initialState: ViewState = {
  sidebar: {
    opened: true,
  },
  section: "conversation",
  analystPane: {
    opened: false,
  },
};

const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    toggleSidebar(state, action: PayloadAction<boolean | undefined>) {
      state.sidebar.opened =
        action.payload === undefined ? !state.sidebar.opened : action.payload;
    },
    setSection(state, action: PayloadAction<AppSectionType>) {
      state.section = action.payload;
    },
    toggleAnalystPane(state, action: PayloadAction<boolean | undefined>) {
      state.analystPane.opened =
        action.payload === undefined ? !state.sidebar.opened : action.payload;
    },
    setAnalystPaneMobileHeader(
      state,
      action: PayloadAction<{ logo?: string; title?: string } | undefined>,
    ) {
      state.analystPane.mobileHeader = action.payload;
    },
  },
});

export const {
  toggleSidebar,
  setSection,
  toggleAnalystPane,
  setAnalystPaneMobileHeader,
} = viewSlice.actions;

export default viewSlice.reducer;
