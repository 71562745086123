export default class CompetitionHelper {
  public static getCompetitionSeasonString = (competition: any): string => {
    if (!competition) return "";

    const year =
      competition.year === competition.end_year
        ? `${competition.year}`
        : `${competition.year}/${competition.end_year}`;
    return `${competition.synthesized_name} ${year}`;
  };
}
