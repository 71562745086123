import { CircularProgress, SxProps, Theme } from "@mui/material";
import { LayoutContainer } from "./LayoutContainer";

type Props = {
  variant: "inline" | "full";
  sx?: SxProps<Theme>;
};

export default function PageLoader(props: Props) {
  const { variant } = props;
  const sx =
    variant === "inline"
      ? { ...props.sx }
      : { height: "100vh", alignItems: "center", ...props.sx };
  return (
    <LayoutContainer contentAlign="center" sx={sx}>
      <CircularProgress size="small" />
    </LayoutContainer>
  );
}
