import React, { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useMediaQuery, GlobalStyles } from "@mui/material";
import {
  createCustomTheme,
  PaletteModeContext,
  ThemeMode,
  getGlobalStyles,
} from "./theme";
import { useAuth0 } from "@auth0/auth0-react";

const AppThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth0();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const defaultThemeMode = "dark";

  const [themeMode, setThemeMode] = useState<ThemeMode>(() => {
    return (localStorage.getItem("themeMode") as ThemeMode) || defaultThemeMode;
  });

  useEffect(() => {
    if (!isAuthenticated) {
      setThemeMode("light");
    } else {
      setThemeMode(
        (localStorage.getItem("themeMode") as ThemeMode) || defaultThemeMode,
      );
    }
  }, [isAuthenticated]);

  const paletteMode = useMemo(
    () => ({
      mode: themeMode,
      setMode: (mode: ThemeMode) => {
        setThemeMode(mode);
        localStorage.setItem("themeMode", mode);
      },
    }),
    [themeMode],
  );

  const theme = useMemo(
    () => createCustomTheme(themeMode, prefersDarkMode),
    [themeMode, prefersDarkMode],
  );
  const globalStyles = useMemo(() => getGlobalStyles(theme), [theme]);

  return (
    <PaletteModeContext.Provider value={paletteMode}>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={globalStyles} />
        {children}
      </ThemeProvider>
    </PaletteModeContext.Provider>
  );
};

export default AppThemeProvider;
