import React, { useMemo } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ProtectedApp from "./layout/ProtectedApp";
import PublicApp from "./layout/PublicApp";
import { UserPermissions } from "./sections/auth/models";
import ReportPage from "./sections/reports/ReportPage";
import ReportsPage from "./sections/reports/ReportsPage";
import ReportFormPage from "./sections/reports/templates/ReportFormPage";
import ReportTemplateForm from "./sections/reports/templates/components/ReportTemplateForm";
import ForbiddenPage from "./pages/ForbiddenPage";
import ChartPage from "./pages/ChartPage";
import ConversationPage from "./sections/conversation/ConversationPage";
import SavedReportsPage from "./sections/reports/saved/SavedReportsPage";
import ProtectedComponent from "./sections/auth/ProtectedComponent";

const AppRouter = () => {
  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route>
            {/* private routes */}
            <Route path="/" element={<ProtectedApp />}>
              <Route
                path="/conversation/:conversationId"
                element={
                  <ProtectedComponent
                    permissions={[UserPermissions.READ_CHAT]}
                    component={ConversationPage}
                  />
                }
              />
              <Route path="saved-reports">
                <Route
                  path=""
                  element={
                    <ProtectedComponent
                      permissions={[UserPermissions.READ_CHAT]}
                      component={SavedReportsPage}
                    />
                  }
                />
                <Route
                  path=":reportId"
                  element={
                    <ProtectedComponent
                      permissions={[UserPermissions.READ_CHAT]}
                      component={ReportPage}
                    />
                  }
                />
              </Route>
              <Route path="report">
                <Route
                  path=""
                  element={
                    <ProtectedComponent
                      permissions={[
                        UserPermissions.READ_OWN_SCOUT_REPORTS,
                        UserPermissions.READ_ALL_SCOUT_REPORTS,
                      ]}
                      component={ReportsPage}
                    />
                  }
                />
                <Route
                  path=":reportId"
                  element={
                    <ProtectedComponent
                      permissions={[
                        UserPermissions.READ_OWN_SCOUT_REPORTS,
                        UserPermissions.READ_ALL_SCOUT_REPORTS,
                      ]}
                      component={ReportPage}
                    />
                  }
                />
                <Route
                  path="create"
                  element={
                    <ProtectedComponent
                      permissions={[UserPermissions.CRAETE_SCOUT_REPORT]}
                      component={ReportFormPage}
                    />
                  }
                />
                <Route
                  path=":reportId/update"
                  element={
                    <ProtectedComponent
                      permissions={[UserPermissions.CRAETE_SCOUT_REPORT]}
                      component={(props) => (
                        <ReportFormPage {...props} variant="update" />
                      )}
                    />
                  }
                />
                <Route path="templates">
                  <Route
                    path="create"
                    element={
                      <ProtectedComponent
                        permissions={[UserPermissions.CREATE_SCOUT_TEMPLATE]}
                        component={ReportTemplateForm}
                      />
                    }
                  />
                  <Route
                    path=":templateId"
                    element={
                      <ProtectedComponent
                        permissions={[UserPermissions.CREATE_SCOUT_TEMPLATE]}
                        component={(props) => (
                          <ReportTemplateForm {...props} variant="update" />
                        )}
                      />
                    }
                  />
                </Route>
              </Route>
            </Route>
            {/* public routes */}
            <Route path="/" element={<PublicApp />}>
              <Route
                path="/reports/:reportId"
                element={<ReportPage isPublic={true} />}
              />
              <Route
                path="/shared-reports/:reportId"
                element={<ReportPage isPublic={true} isShared={true} />}
              />
              <Route path="/forbidden" element={<ForbiddenPage />} />
              <Route path="/charts" element={<ChartPage />} />
            </Route>
          </Route>,
        ),
      ),
    [],
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
