import React, { useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useAuth0 } from "@auth0/auth0-react";
import { fetch } from "../../store/slices/reportTemplatesSlice";
import { ReportTemplate } from "../../models";
import BottomSwipeableDrawer from "../../../../shared/components/BottomSwipeableDrawer";

interface SelectTemplateProps {
  filter?: (template: ReportTemplate) => boolean;
  onSelect: (template: ReportTemplate) => void;
}

interface SelectListProps {
  items: ReportTemplate[];
  onSelect: (template: ReportTemplate) => void;
}

function SelectList({ items, onSelect }: SelectListProps) {
  return (
    <List
      dense
      className="scrollable"
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        padding: 0,
      }}
    >
      <ListSubheader sx={{ background: "transparent", pb: "4px" }}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            color: (theme) => theme.palette.text.disabled,
          }}
        >
          Templates
        </Typography>
      </ListSubheader>
      {items.map((template) => (
        <ListItemButton
          key={`report-${template.id}`}
          onClick={() => {
            onSelect(template);
          }}
        >
          <ListItemText>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {template.name}
            </Typography>
          </ListItemText>
        </ListItemButton>
      ))}
    </List>
  );
}

export default function SelectTemplate({
  onSelect,
  filter = (template) => true,
}: SelectTemplateProps) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { items } = useSelector(
    (state: RootState) => state.reports.templates.list,
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getTemplates = async () => {
      dispatch(fetch(await getAccessTokenSilently()));
    };

    getTemplates();
  }, [getAccessTokenSilently, dispatch]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        endIcon={<AddIcon />}
        onClick={handleClick}
      >
        Create new
      </Button>
      {isMobile ? (
        <BottomSwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={() => handleClose()}
          onOpen={() => {}}
        >
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                paddingTop: "24px",
              },
            }}
          >
            <SelectList
              items={items.filter((template) => filter(template))}
              onSelect={(template) => {
                onSelect(template);
                handleClose();
              }}
            />
          </Box>
        </BottomSwipeableDrawer>
      ) : (
        <Menu
          id="select-template-props-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            zIndex: 9999,
            "& .MuiPaper-root": {
              minWidth: "210px",
              elevation: 1,
              background: (theme) => theme.palette.backgrounds.menu,
            },
          }}
        >
          <SelectList
            items={items.filter((template) => filter(template))}
            onSelect={(template) => {
              onSelect(template);
              handleClose();
            }}
          />
        </Menu>
      )}
    </>
  );
}
