import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../../api/twelve";
import { User, UserPermissions } from "../models";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";

interface CurrentUserContextProps {
  currentUser: User | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const CurrentUserContext = createContext<CurrentUserContextProps | undefined>(
  undefined,
);

interface CurrentUserProviderProps {
  children: ReactNode;
}

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [currentUser, setCurrentUser] = useState<User>();
  const section = useSelector((state: RootState) => state.view.section);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    switch (section) {
      case "conversation":
        if (!currentUser.chekPermissions(UserPermissions.READ_CHAT)) {
          navigate("/report");
        }
        break;
      case "reports":
        if (
          !currentUser.chekPermissions([
            UserPermissions.READ_ALL_SCOUT_REPORTS,
            UserPermissions.READ_OWN_SCOUT_REPORTS,
            UserPermissions.CRAETE_SCOUT_REPORT,
            UserPermissions.CREATE_SCOUT_TEMPLATE,
          ])
        ) {
          navigate("/forbidden");
        }
        break;
      default:
        break;
    }
  }, [section, currentUser, navigate]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const me = await api.me(token);

        setCurrentUser(me);
      } catch (error) {
        console.error("Failed to fetch permissions:", error);
      }
    };

    if (isAuthenticated) {
      fetchPermissions();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
