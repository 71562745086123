import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";
import { api } from "../../../../api/twelve";
import {
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import SearchDropdown from "../../../../shared/components/SearchDropdown";
import {
  CompetitionInfo,
  PlayerQualityInfo,
  SearchPlayerInfo,
} from "../../../../models";
import StringHelper from "../../../../shared/helpers/string.helper";

export interface SelectedPlayer {
  info: SearchPlayerInfo;
  competition: CompetitionInfo;
  quality: PlayerQualityInfo;
  other_competition?: CompetitionInfo;
}
export interface SelectPlayerProps {
  onSelect: (player: SelectedPlayer | undefined) => void;
  label?: string;
  position?: string;
  variant?: "evaluate" | "comparison" | "transfer";
  defaultPlayer?: SelectedPlayer;
  expanded?: boolean;
  noOptionsText?: string;
  gender?: string;
}

const SelectPlayer: React.FC<SelectPlayerProps> = ({
  onSelect,
  label = "Player",
  position,
  variant,
  defaultPlayer,
  expanded = false,
  noOptionsText = "Find players by their first name and/or last name.",
  gender,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedPlayer, setSelectedPlayer] = useState<SelectedPlayer>();
  const [qualities, setQualities] = useState<PlayerQualityInfo[]>();

  const [competitionList, setCompetitionList] =
    useState<Array<CompetitionInfo>>();

  const [competitions, setCompetitions] = useState<CompetitionInfo[]>();
  const [seasons, setSeasons] = useState<{ id: number; name: string }[]>();
  const [otherSeasons, setOtherSeasons] =
    useState<{ id: number; name: string }[]>();

  useEffect(() => {
    if (defaultPlayer) {
      setSelectedPlayer((prev) => {
        return { ...(prev || {}), ...defaultPlayer };
      });
      setQualities([
        { display_name: "Summary", quality: undefined },
        ...(defaultPlayer.info?.relevant_qualities || []),
      ]);
    } else {
      setSelectedPlayer(undefined);
    }
  }, [defaultPlayer]);

  useEffect(() => {
    setQualities([
      { display_name: "Summary", quality: undefined },
      ...(selectedPlayer?.info?.relevant_qualities || []),
    ]);

    if (selectedPlayer?.info?.id) {
      const getPlayerCareer = async () => {
        const token = await getAccessTokenSilently();
        const result = await api.getPlayerCareerInfo(
          token,
          selectedPlayer.info.id,
        );

        setCompetitionList(result);

        if (result.length) {
          setSelectedPlayer((prev) => {
            return {
              ...prev,
              competition: !prev?.competition
                ? {
                    id: result[0].id,
                    year: result[0].year,
                  }
                : prev?.competition,
            } as SelectedPlayer;
          });
        }
      };

      getPlayerCareer();
    } else {
      setCompetitionList(undefined);
      setCompetitions(undefined);
      setSeasons(undefined);
      setQualities(undefined);
    }
  }, [
    selectedPlayer?.info?.id,
    selectedPlayer?.info?.relevant_qualities,
    getAccessTokenSilently,
  ]);

  const fetchPlayers = useMemo(
    () => async (query: string) => {
      const result = await api.searchPlayersFullText(
        await getAccessTokenSilently(),
        query,
        position,
        gender,
      );

      return result.map((player) => ({
        id: player.id,
        name: player.name,
        data: player,
      }));
    },
    [getAccessTokenSilently, position, gender],
  );

  const fetchCompetitions = useMemo(
    () => async (query: string) => {
      const gender = competitionList?.find(
        (c) => c.id === selectedPlayer?.competition?.id,
      )?.gender?.nouns;
      const result = await api.searchCompetitionsFullText(
        await getAccessTokenSilently(),
        query,
        gender ? (gender === "women" ? "female" : "male") : undefined,
      );

      return result
        .filter((c) => c.id !== selectedPlayer?.competition?.id)
        .map((competition) => ({
          id: competition.id,
          name: competition.synthesized_name,
          data: competition,
        }));
    },
    [getAccessTokenSilently, selectedPlayer, competitionList],
  );

  useEffect(() => {
    setSelectedPlayer((prev) => {
      return {
        ...(prev || {}),
        quality: qualities?.[0],
      } as SelectedPlayer;
    });
  }, [qualities]);

  useEffect(() => {
    setCompetitions(
      competitionList
        ? Array.from(
            competitionList
              .reduce((map, c) => {
                const key = `${c.id}-${c.synthesized_name}`;
                if (!map.has(key)) {
                  map.set(key, c);
                }
                return map;
              }, new Map())
              .values(),
          )
        : undefined,
    );
  }, [competitionList]);

  useEffect(() => {
    setSeasons(
      competitionList
        ? Array.from(
            competitionList
              .filter((c) => c.id === selectedPlayer?.competition?.id)
              .reduce((map, c) => {
                const key = `${c.year}`;
                if (!map.has(key)) {
                  map.set(key, {
                    id: c.year,
                    name:
                      c.year === c.end_year
                        ? c.end_year
                        : `${c.year}/${c.end_year}`,
                  });
                }
                return map;
              }, new Map())
              .values(),
          )
        : undefined,
    );
  }, [selectedPlayer?.competition?.id, competitionList]);

  useEffect(() => {
    const fetchCompetitionSeasons = async () => {
      setOtherSeasons(undefined);
      if (!selectedPlayer?.other_competition?.id) return;
      const token = await getAccessTokenSilently();
      const result = await api.getCompetitionSeasons(
        token,
        selectedPlayer.other_competition.id,
      );
      setOtherSeasons(
        result
          .sort((a, b) => b.year - a.year)
          .map((item) => ({
            id: item.year,
            name:
              item.year === item.end_year
                ? `${item.end_year}`
                : `${item.year}/${item.end_year}`,
          })),
      );
    };

    fetchCompetitionSeasons();
  }, [selectedPlayer?.other_competition?.id, getAccessTokenSilently]);

  useEffect(() => {
    setSelectedPlayer((prev) => {
      return {
        ...(prev || {}),
        other_competition: {
          ...(prev?.other_competition || {}),
          year: otherSeasons?.[0]?.id,
        },
      } as SelectedPlayer;
    });
  }, [otherSeasons]);

  const handleCompetitionChange = (event: SelectChangeEvent) => {
    setSelectedPlayer((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        competition: competitionList?.find((c) => c.id === +event.target.value),
      } as SelectedPlayer;
    });
  };

  const handleOtherCompetitionChange = (competition: CompetitionInfo) => {
    setSelectedPlayer((prev) => {
      return {
        ...(!prev ? {} : prev),
        other_competition: { id: competition?.id },
      } as SelectedPlayer;
    });
  };

  useEffect(() => {
    onSelect(selectedPlayer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayer]);

  const handleSeasonChange = (event: SelectChangeEvent) => {
    setSelectedPlayer((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        competition: prev.competition
          ? {
              ...prev.competition,
              year: +event.target.value,
            }
          : prev.competition,
      } as SelectedPlayer;
    });
  };

  const handleOtherSeasonChange = (event: SelectChangeEvent) => {
    setSelectedPlayer((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        other_competition: prev.other_competition
          ? {
              ...prev.other_competition,
              year: +event.target.value,
            }
          : prev.other_competition,
      } as SelectedPlayer;
    });
  };

  const handleQualityChange = (event: SelectChangeEvent) => {
    setSelectedPlayer((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        quality: qualities?.find((q) => q.display_name === event.target.value),
      } as SelectedPlayer;
    });
  };

  return (
    <Stack gap={expanded ? "32px" : "16px"}>
      <SearchDropdown
        label={label}
        noOptionsText={noOptionsText}
        value={
          {
            id: selectedPlayer?.info?.id,
            name: selectedPlayer?.info?.name,
            data: selectedPlayer?.info,
          } as any
        }
        fetchData={fetchPlayers}
        onSelect={(player) => {
          const selectedPlayer = {
            info: player?.data,
          } as SelectedPlayer;
          setSelectedPlayer((prev) => {
            return {
              ...selectedPlayer,
              other_competition: prev?.other_competition,
            } as SelectedPlayer;
          });
        }}
        renderOption={(option) => (
          <Stack sx={{ width: "100%" }}>
            <Typography variant="body1">{option?.name}</Typography>
            <Stack direction="row" spacing={1} justifyContent={"space-between"}>
              <Typography variant="caption" color="text.secondary">
                {option?.data?.team}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {StringHelper.normalizeKey(option?.data?.position)}
              </Typography>
            </Stack>
          </Stack>
        )}
      />
      <Collapse in={expanded}>
        <Stack gap={"32px"}>
          <FormControl
            variant={"standard"}
            fullWidth
            sx={{ minWidth: 120 }}
            size="small"
          >
            <InputLabel id="competition-label">Competition</InputLabel>
            <Select
              labelId="competition-label"
              id="competition-select"
              value={
                selectedPlayer?.competition?.id
                  ? String(selectedPlayer?.competition?.id)
                  : ""
              }
              label="Competition"
              onChange={handleCompetitionChange}
              disabled={!competitions}
              renderValue={(value) => {
                return (
                  competitions?.find((c) => c.id === +value)
                    ?.synthesized_name || ""
                );
              }}
            >
              {competitions?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Stack direction={"column"} spacing={0} width={"100%"}>
                    <Typography variant="body1" color="text.primaty">
                      {item.name}
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        textTransform={"capitalize"}
                      >
                        {item.gender?.nouns}s
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {item.country}
                      </Typography>
                    </Stack>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant={"standard"}
            fullWidth
            sx={{ minWidth: 120 }}
            size="small"
          >
            <InputLabel id="season-label">Season</InputLabel>
            <Select
              labelId="season-label"
              id="season-select"
              value={
                selectedPlayer?.competition?.year
                  ? String(selectedPlayer?.competition?.year)
                  : ""
              }
              label="Season"
              onChange={handleSeasonChange}
              disabled={!seasons || !selectedPlayer?.competition?.id}
            >
              {seasons?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {variant === "evaluate" && (
            <FormControl
              variant={"standard"}
              fullWidth
              sx={{ minWidth: 120 }}
              size="small"
            >
              <InputLabel id="quality-label">Quality</InputLabel>
              <Select
                labelId="quality-label"
                id="quality-select"
                value={selectedPlayer?.quality?.display_name || ""}
                label="Quality"
                onChange={handleQualityChange}
                disabled={!qualities}
              >
                {qualities?.map((item, index) => (
                  <MenuItem key={`quality-${index}`} value={item.display_name}>
                    {item.display_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </Collapse>
      {variant === "transfer" && (
        <>
          <SearchDropdown
            label={"Other competition"}
            noOptionsText="Find competitions by their name."
            fetchData={fetchCompetitions}
            onSelect={(option) => {
              handleOtherCompetitionChange(option?.data);
            }}
            renderOption={(option) => (
              <Stack sx={{ width: "100%" }}>
                <Typography variant="body1">{option?.name}</Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent={"space-between"}
                >
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {`${(option?.data as CompetitionInfo)?.gender?.nouns}s`}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {(option?.data as CompetitionInfo)?.country}
                  </Typography>
                </Stack>
              </Stack>
            )}
          />
          <Collapse in={expanded}>
            <Stack gap={"32px"}>
              <FormControl
                variant={"standard"}
                fullWidth
                sx={{ minWidth: 120 }}
                size="small"
              >
                <InputLabel id="other-season-label">Other season</InputLabel>
                <Select
                  labelId="other-season-label"
                  id="other-season-select"
                  value={
                    selectedPlayer?.other_competition?.year
                      ? String(selectedPlayer?.other_competition?.year)
                      : ""
                  }
                  label="Other season"
                  onChange={handleOtherSeasonChange}
                  disabled={!selectedPlayer?.other_competition?.id}
                >
                  {otherSeasons?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Collapse>
        </>
      )}
    </Stack>
  );
};

export default SelectPlayer;
