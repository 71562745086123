import React, { useEffect, useState } from "react";
import { css, styled, IconButton, Box, Button } from "@mui/material";
import { LayoutContainer } from "../../../shared/components/LayoutContainer";
import { MessageModel } from "../../../api/twelve";
import { ReactComponent as SvgLogo } from "../../../shared/icons/logo-circle-transparent.svg";
import { Chart } from "../../../shared/components/charts/Chart";
import SwipeableContent from "../../../shared/components/SwipeableContent";
import MarkdownText from "../../../shared/components/MarkdownText";
import {
  CopyAllRounded,
  ThumbDownAltOutlined,
  CheckRounded,
  ThumbDownAlt,
} from "@mui/icons-material";
import ChartHelper from "../../../shared/helpers/chart.helper";
import { CustomRouterLink } from "../../../shared/components/CustomRouterLink";
import AudioPlayerButton from "./AudioPlayerButton";

export type MessageTextVariant = "system" | "user" | "pending" | "assistant";

interface MessageTextProps {
  variant?: MessageTextVariant;
}

interface ToolbarProps {
  permanent: boolean | null;
}

const MessageText = styled("div", {
  shouldForwardProp: (prop) => prop !== "variant",
})<MessageTextProps>(
  ({ variant, theme }) => css`
    display: inline-block;
    max-width: 100%;
    border-radius: 24px;
    padding: ${variant === "user" ? "0 1rem" : 0};
    color: "auto";
    background-color: ${variant === "user"
      ? theme.palette.backgrounds.secondary
      : "transparent"};
    ${variant === "assistant" &&
    css`
      p:first-of-type {
        margin-top: 0px;
      }
      p:last-of-type {
        margin-bottom: 0px;
      }
    `}
  `,
);

const Logo = styled(SvgLogo, {
  shouldForwardProp: (prop) => prop !== "height",
})(({ theme }) => ({
  "&": css`
    width: 24px;
    min-width: 24px;
    height: 24px;
  `,
  "& circle": {
    stroke: theme.palette.primary.main,
  },
}));

const ToolbarContainer = styled("div")`
  height: 30px;
`;

const Toolbar = styled("div", {
  shouldForwardProp: (prop) => prop !== "permanent",
})<ToolbarProps>(
  ({ permanent, theme }) => css`
    position: relative;
    left: -19px;
    border: 1px solid ${theme.palette.divider};
    border-radius: 10px;
    padding: 3px;
    max-width: 120px;
    visibility: hidden;
    transform: scale(0.8);
  `,
);

const StyledLayoutContainer = styled(LayoutContainer, {
  shouldForwardProp: (prop) => prop !== "permanent",
})<ToolbarProps>(
  ({ permanent }) => css`
    &:hover .toolbar {
      visibility: visible;
    }
  `,
);

function Plots({ plots }: { plots: Array<any> }) {
  const [orderedPlots, setOrderedPlots] = useState<Array<any>>();

  useEffect(() => {
    setOrderedPlots(
      plots!.sort(
        (a, b) =>
          ChartHelper.ORDER[ChartHelper.GetChartVariantFromUrl(a.url)] -
          ChartHelper.ORDER[ChartHelper.GetChartVariantFromUrl(b.url)],
      ),
    );
  }, [plots]);

  return orderedPlots?.length ? (
    <SwipeableContent
      descriptions={orderedPlots
        ?.filter((p) => p.description)
        .map((p) => p.description)}
      allowExpand={false}
      textColor={"auto"}
      minHeight={"436px"}
    >
      {orderedPlots?.map((p: any, i: number) => (
        <Chart
          url={p.url}
          key={`chart _${i}`}
          variant={ChartHelper.GetChartVariantFromUrl(p.url)}
          json_body={p.json_body}
          size={"sm"}
        />
      ))}
    </SwipeableContent>
  ) : (
    <></>
  );
}

type MessageProps = {
  chatId: number;
  message: MessageModel;
  permaToolbar: boolean;
  onUpdateDisliked?: (messageId: number, disliked: boolean) => void;
};

export function MessageItem(props: MessageProps) {
  const { message, permaToolbar, onUpdateDisliked } = props;
  const messageId = (message as MessageModel).id ?? null;
  const messageDisliked = (message as MessageModel).disliked ?? false;
  const content = message.content;
  const variant = message.role as MessageTextVariant;
  const [copied, setCopied] = useState(false);
  const [hasDisliked, setHasDisliked] = useState(messageDisliked);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleUpdateDislike = () => {
    if (onUpdateDisliked && messageId) {
      onUpdateDisliked(messageId, !hasDisliked);
      setHasDisliked(!hasDisliked);
    }
  };

  return (
    <StyledLayoutContainer
      contentAlign={variant === "user" ? "end" : "start"}
      sx={{
        gap: "1rem",
      }}
      permanent={permaToolbar}
    >
      {variant !== "user" && <Logo />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: `${variant === "user" ? "auto" : "100%"}`,
          gap: "1rem",
        }}
      >
        {message.plots && <Plots plots={message.plots} />}
        <MessageText variant={variant}>
          <MarkdownText text={content}></MarkdownText>
          {variant === "assistant" && message.report_id && (
            <Button
              sx={{
                marginTop: "8px",
              }}
              component={CustomRouterLink}
              to={{
                pathname: `/saved-reports/${message.report_id}`,
              }}
            >
              Open report
            </Button>
          )}
          {variant === "assistant" && (
            <>
              <ToolbarContainer>
                <Toolbar permanent={permaToolbar} className="toolbar">
                  <IconButton onClick={handleUpdateDislike}>
                    {hasDisliked ? (
                      <ThumbDownAlt fontSize="small" />
                    ) : (
                      <ThumbDownAltOutlined fontSize="small" />
                    )}
                  </IconButton>
                  <IconButton onClick={handleCopy}>
                    {copied ? (
                      <CheckRounded fontSize="small" />
                    ) : (
                      <CopyAllRounded fontSize="small" />
                    )}
                  </IconButton>
                  <AudioPlayerButton text={content} />
                </Toolbar>
              </ToolbarContainer>
            </>
          )}
        </MessageText>
      </Box>
    </StyledLayoutContainer>
  );
}
